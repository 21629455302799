/* eslint-disable no-plusplus */
import dollarsToCents from 'dollars-to-cents';
import { fetchUtils } from 'react-admin';
import * as strip from 'js-striphtml';
import firebaseAuthInstance from '../config/firebaseAuth';
import firestore from '../config/firestore';
import { isShippableFalseExceptionsHotfix } from '../constants/returnTypes';
import {
    sanitizeDataFirestore,
    setAuthToken,
    uploadBundledOptions,
    uploadFirestoreImages,
    uploadVariantImages,
    randomId,
    flattenArrayProperties,
    formatPositionsArray,
    sanitizeProductBundledOptions,
    sanitizeGalleryImages,
    getProductRegionLinks,
    uploadVariationToFirestore,
    generateProductIdForBundledOptions,
    generateSwatchIds,
    sanitizeVariantImages,
    sanitizeGetItemData,
    saveAcrossDrafts
} from './util';
import { getUser } from '../auth/AuthConfig';

const BUNDLE = 'bundle';
const CONFIGURABLE = 'configurable';

const httpClient = fetchUtils.fetchJson;

export const matchSkuToProductID = async (resource, sku) => {
    let query = firestore.collection(resource);
    query = query.where('productSkus', 'array-contains', `${sku}`);
    const allDocs = await query.get().then(snapshot => snapshot.docs);
    let doc = allDocs[0]?.data();
    if (!doc) return null;
    if (doc.typeId && doc.typeId === 'configurable') {
        doc = doc.variants.find(r => r.sku === sku);
    }
    return doc.productId;
};

const sanitizeProductData = (productData) => {
    if (productData === undefined || productData === null) {
        return null;
    }

    if (Array.isArray(productData)) {
        for (let i = 0; i < productData.length; i++) {
            productData[i] = sanitizeProductData(productData[i]);
        }
    } else if (typeof productData === 'object') {
        for (const key in productData) {
            if (productData.hasOwnProperty(key)) {
                productData[key] = sanitizeProductData(productData[key]);
            }
        }
    }

    return productData;
}

export const getProductList = async (firestore, resource, params) => {
    const collection = firestore.collection(resource);
    let query = collection;

    if (params.filter.sku) {
        query = query.where('productSkus', 'array-contains', params.filter.sku);
    }

    if (params.filter.status) {
        query = query.where('status', '==', params.filter.status);
    }

    if (params.sort.field !== 'id') {
        query = query.orderBy(
            params.sort.field,
            params.sort.order.toString().toLowerCase()
        );
    }

    const allDocs = await query.get().then(snapshot => snapshot.docs);
    let curDocs = [];

    if (allDocs.length > 0) {
        let startIndex;
        let endIndex;

        if (params.filter.title) {
            startIndex = 0;
            endIndex = allDocs.length;
        } else {
            startIndex =
                (params.pagination.page - 1) * params.pagination.perPage;
            endIndex =
                startIndex + params.pagination.perPage > allDocs.length
                    ? allDocs.length
                    : startIndex + params.pagination.perPage;
        }

        for (let i = startIndex; i < endIndex; i++) {
            const data = allDocs[i].data();
            if (params.filter.title) {
                const searchTitle = params.filter.title
                    .toString()
                    .toLowerCase();
                if (
                    data.title &&
                    data.title.toLowerCase().includes(searchTitle)
                ) {
                    curDocs.push({ ...data, id: allDocs[i].id });
                }
            } else {
                curDocs.push({ ...data, id: allDocs[i].id });
            }
        }
    }

    const titleSearchTotal = curDocs.length;

    if (params.filter.title && curDocs.length > params.pagination.perPage) {
        const first = (params.pagination.page - 1) * params.pagination.perPage;
        curDocs = curDocs.slice(first, first + params.pagination.perPage);
    }

    return {
        data: curDocs,
        total: params.filter.title ? titleSearchTotal : allDocs.length
    };
};

export const getProduct = async (firestore, resource, params) => {
    let product;
    const position = [];
    await firestore
        .collection(resource)
        .doc(params.id)
        .get()
        .then(async data => {
            const docs = data.data();

            sanitizeGetItemData(docs);

            /**
             * grab referenct to the doc in other collections.
             */
            docs.links = await getProductRegionLinks(resource, docs.sku);
            /**
             * position : ["43:43"]
             * category = [index].split(':')[0]
             * position = [index].split(':')[1]
             */

            if (docs.positions) {
                docs.positions.map(pos => {
                    if (pos.split) {
                        position.push({
                            category: pos.split(':')[0],
                            positioncategory: pos.split(':')[1]
                        });
                    }
                });
            }

            product = docs;
        });

    return {
        data: {
            ...product,
            id: params.id,
            positions: position
        }
    };
};

const sortVariants = variants => {
    const sortedVariants = variants.sort(
        (a, b) => Number(a.swatches.position) - Number(b.swatches.position)
    );

    return sortedVariants.length === variants.length
        ? sortedVariants
        : variants;
};

export const createProduct = async (firestore, resource, params) => {
    if (params && params.data) {
        const productData = sanitizeProductData(params.data);

        await uploadFirestoreImages(
            productData.sku,
            productData.image,
            productData.swatches,
            ''
        );
        if (productData.image?.gallery) {
            sanitizeGalleryImages(productData.image.gallery);
        }

        if (
            productData.typeId === BUNDLE &&
            productData.bundledOptions &&
            productData.bundledOptions.length
        ) {
            await uploadBundledOptions(
                productData.sku,
                productData.bundledOptions
            );
            sanitizeProductBundledOptions(productData.bundledOptions);
            generateProductIdForBundledOptions(productData.bundledOptions);
        } else {
            productData.bundledOptions = null;
        }

        if (productData.typeId === CONFIGURABLE) {
            if (productData.variants) {
                await uploadVariantImages(
                    productData.sku,
                    productData.variants
                );
                await sanitizeVariantImages(productData.variants);
            } else {
                productData.variants = null;
            }
        }

        if (productData.positions && productData.positions.length > 0) {
            const flatPositionsArray = flattenArrayProperties(
                productData.positions
            );
            const positions = formatPositionsArray(flatPositionsArray);
            productData.positions = positions;
        }

        if (!productData.productId) {
            productData.productId = randomId();
        }

        if (productData.price) {
            productData.priceInCents = dollarsToCents(productData.price);
        }

        if (productData.description) {
            let strippedProductDescription = strip.stripTags(
                productData.description
            );
            productData.simpleDescription = strippedProductDescription;
        } else {
            productData.description = productData.title || '';
        }
        /**
         * populate swatch ids to randomId if it is not defined
         */
        if (productData.swatches) {
            generateSwatchIds(productData.swatches);
        }

        if (productData.sku) {
            productData.productSkus = [productData.sku];

            if (productData.variants && productData.variants.length) {
                productData.variants.map(variant => {
                    productData.productSkus.push(variant.sku);
                    variant.priceInCents = dollarsToCents(variant.price);
                    variant.draft = variant.draft || true;
                });

                productData.variants = sortVariants(productData.variants);
            }
        }

        if (productData.upc) {
            productData.barcode = productData.upc;
        }

        if (!productData.ingredients) {
            productData.ingredients = null;
        }

        const user = await firebaseAuthInstance.auth().currentUser;

        productData.logging = {
            email: user.email,
            uid: user.uid,
            createdAt: new Date().toLocaleString()
        };

        if (productData.variations && productData.variations.length > 1) {
            const results = [];
            for (let i = 0; i < productData.variations.length; i += 1) {
                if (!resource.includes(productData.variations[i])) {
                    const variationResource = `products-${productData.variations[i]}`;
                    results.push(
                        uploadVariationToFirestore(
                            variationResource,
                            productData,
                            firestore
                        )
                    );
                }
            }
            Promise.all(results);
        }

        const createProductData = params.data;

        await firestore
            .collection(resource)
            .add(createProductData)
            .then(() => {
                console.info('document successfully created');
            })
            .catch(error => {
                console.error(error);
            });
        return { data: { ...createProductData, id: params.id } };
    }
};

export const updateProduct = async (firestore, resource, params) => {
    const updatedProductInformation = sanitizeProductData(params.data);

    const emptyActiveIngredients = !updatedProductInformation.ingredients
        ?.activeIngredients?.length;
    const emptyFullIngredeints = !updatedProductInformation.ingredients
        ?.fullIngredients?.length;
    const emptyAfterIngredients = !updatedProductInformation.ingredients
        ?.afterIngredients?.length;

    if (updatedProductInformation.price) {
        updatedProductInformation.priceInCents = dollarsToCents(
            updatedProductInformation.price
        );
    }

    if (updatedProductInformation.bundledOptions) {
        await uploadBundledOptions(
            updatedProductInformation.sku,
            updatedProductInformation.bundledOptions
        );
    }

    if (
        updatedProductInformation.positions &&
        updatedProductInformation.positions.length > 0
    ) {
        const flatPositionsArray = flattenArrayProperties(
            updatedProductInformation.positions
        );
        const positions = formatPositionsArray(flatPositionsArray);
        updatedProductInformation.positions = positions;
    }

    await uploadFirestoreImages(
        updatedProductInformation.sku,
        updatedProductInformation.image,
        updatedProductInformation.swatches,
        ''
    );

    if (
        updatedProductInformation.custom &&
        updatedProductInformation.size === 'custom'
    ) {
        updatedProductInformation.size = {
            size: updatedProductInformation.size,
            custom: updatedProductInformation.custom
        };
    }

    if (
        updatedProductInformation.image &&
        updatedProductInformation.image.gallery
    ) {
        sanitizeGalleryImages(updatedProductInformation.image.gallery);
    }

    if (updatedProductInformation.bundledOptions?.length) {
        sanitizeProductBundledOptions(updatedProductInformation.bundledOptions);
        generateProductIdForBundledOptions(
            updatedProductInformation.bundledOptions
        );
    } else {
        updatedProductInformation.bundledOptions = null;
    }

    if (updatedProductInformation.variants) {
        await uploadVariantImages(
            updatedProductInformation.sku,
            updatedProductInformation.variants
        );

        await sanitizeVariantImages(updatedProductInformation.variants);

        updatedProductInformation.variants.forEach(variant => {
            variant.priceInCents = dollarsToCents(variant.price);
            variant.barcode = variant.upc || null;
        });

        updatedProductInformation.variants = sortVariants(
            updatedProductInformation.variants
        );
    }

    /**
     * set empty variants to null to avoid  having search impacts pricing when
     * presenting a sku that has an empty `variants` array in that the
     * code looks at the first object in the array for price, then falls back to top level price.
     * If there are no objects in the `variants` array, it appears free when searching
     */

    if (
        updatedProductInformation.variants &&
        !updatedProductInformation.variants.length
    ) {
        updatedProductInformation.variants = null;
    }

    if (
        updatedProductInformation.ingredients &&
        emptyActiveIngredients &&
        emptyFullIngredeints &&
        emptyAfterIngredients
    ) {
        updatedProductInformation.ingredients = null;
    }

    /**
     * populate swatch ids to randomId if it is not defined
     */
    if (updatedProductInformation.swatches) {
        generateSwatchIds(updatedProductInformation.swatches);
    }
    /**
     * strip the description of html tags and insert the description
     * into simpleDescription
     */

    if (updatedProductInformation.description) {
        const strippedProductDescription = strip.stripTags(
            updatedProductInformation.description
        );
        updatedProductInformation.simpleDescription = strippedProductDescription;
    } else {
        updatedProductInformation.description =
            updatedProductInformation.title || '';
    }

    if (updatedProductInformation.sku) {
        updatedProductInformation.productSkus = [updatedProductInformation.sku];

        if (
            updatedProductInformation.variants &&
            updatedProductInformation.variants.length
        ) {
            updatedProductInformation.variants.map(variant => {
                updatedProductInformation.productSkus.push(variant.sku);
            });
        }
    }

    if (updatedProductInformation.upc) {
        updatedProductInformation.barcode = updatedProductInformation.upc;
    }

    /**
     * log changes made by user
     */
    const user = await firebaseAuthInstance.auth().currentUser;
    updatedProductInformation.logging = {
        email: user.email,
        uid: user.uid,
        updatedAt: new Date().toLocaleString()
    };

    if (
        isShippableFalseExceptionsHotfix.includes(updatedProductInformation.sku)
    ) {
        updatedProductInformation.isShippable = false;
    }

    // mark the product as dirty so other users know there have been some changes.
    if (!updatedProductInformation.doNotSetUnmerge) {
        updatedProductInformation.unMergedChanges = true;
    } else {
        updatedProductInformation.unMergedChanges = false;
        delete updatedProductInformation.doNotSetUnmerge;
    }

    if (updatedProductInformation.parentCategoryIds) {
        updatedProductInformation.parentCategoryIds.sort((a, b) => a - b);
    }

    const { autoFlipDateUTC } = updatedProductInformation;
    if (autoFlipDateUTC) {
        try {
            const UTCString = autoFlipDateUTC.toUTCString();
            updatedProductInformation.autoFlipDateUTC = UTCString;
        } catch (error) {
            console.log('Issue with auto publish date: ', autoFlipDateUTC);
            updatedProductInformation.autoFlipDateUTC = null;
        }
    }

    const displayName = getUser().displayName;

    try {
        const saveAcross = updatedProductInformation.saveAcrossLocales;
        delete updatedProductInformation.saveAcrossLocales;

        await firestore
            .collection(resource)
            .doc(params.id)
            .update({
                ...updatedProductInformation,
                lastEditedBy: displayName
            });

        if (saveAcross) {
            await saveAcrossDrafts(
                updatedProductInformation,
                displayName
            );
        }
    } catch (error) {
        console.log(error);
    }

    return {
        data: { id: params.id, ...updatedProductInformation }
    };
};

export const deleteProduct = (db, resource, params) => {
    const url = `https://firestore.googleapis.com/v1beta1/projects/${db}/databases/(default)/documents/${resource}/${params.id}`;
    const options = setAuthToken();
    options.method = 'PATCH';

    //Set status to 0 to disable
    params.previousData.fields.status.integerValue = 0;

    const payload = {
        fields: sanitizeDataFirestore(params.previousData.fields)
    };
    options.body = JSON.stringify(payload);

    return httpClient(url, options).then(response => {
        return { data: params };
    });
};
