/* eslint-disable react/prop-types */
import React from 'react';

import { BooleanInput } from 'react-admin';
import NumberInput from '../components/ReactAdminCustom/NumberInput';

import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const AutoshipSettingsComponent = props => {
    const {
        autoshipPeriod,
        autoshipEligible,
        autoshipFrequency
    } = props
    return (
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Autoship Settings</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
                <BooleanInput
                    label="Autoship Eligible"
                    source={autoshipEligible}
                />
                <NumberInput
                    label="Autoship Frequency"
                    source={autoshipFrequency}
                />
                <NumberInput label="Autoship Period" source={autoshipPeriod} />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default AutoshipSettingsComponent;
