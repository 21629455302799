import { processQuery } from './Utils';

export const getLogsList = async (firestore, resource, params) => {
    const collection = firestore.collection(resource);
    let query = collection;

    return processQuery(params, query, Object.keys(params.filter));
};

export const getLog = async (firestore, resource, params) => {
    let log;

    await firestore
        .collection(resource)
        .doc(params.id)
        .get()
        .then(data => {
            log = data.data();
        });

    return { data: { ...log, id: params.id } };
};
