import React from 'react';
import { validateIngredientId } from '../validation/Ingredient';
import { FormToolbar } from '../components/FormToolbar';
import {
    SimpleForm,
    TextInput,
    BooleanInput,
    ArrayInput,
    SimpleFormIterator,
    LongTextInput
} from 'react-admin';
import NumberInput from '../components/ReactAdminCustom/NumberInput';

const sanitizeRestProps = ({
    anyTouched,
    array,
    asyncValidate,
    asyncValidating,
    autofill,
    blur,
    basePath,
    change,
    clearAsyncError,
    clearFields,
    clearSubmit,
    clearSubmitErrors,
    destroy,
    dirty,
    dispatch,
    form,
    handleSubmit,
    hasList,
    hasEdit,
    hasCreate,
    hasShow,
    initialize,
    initialized,
    initialValues,
    pristine,
    pure,
    redirect,
    reset,
    resetSection,
    submit,
    submitFailed,
    submitSucceeded,
    submitting,
    touch,
    translate,
    triggerSubmit,
    untouch,
    valid,
    validate,
    ...props
}) => props;

export const IngredientForm = props => {
    return (
        <SimpleForm {...sanitizeRestProps(props)} toolbar={<FormToolbar />}>
            <TextInput label="Title" source="title" />
            <TextInput label="Source" source="source" />
            <BooleanInput label="Enabled" source="enabled" />
            <ArrayInput
                label="Associated Product IDs"
                source="associatedProductIds"
            >
                <SimpleFormIterator>
                    <NumberInput label="Product ID" source="" />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput label="Properties" source="properties">
                <SimpleFormIterator>
                    <TextInput label="Title" source="title" />
                    <LongTextInput label="Description" source="description" />
                    <BooleanInput label="Enabled" source="enabled" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    );
};
