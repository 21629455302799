import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { IngredientParse } from '../../dataProvider/Utils/IngredientParse';
import IngredientStepper from './IngredientStepper';

export default class IngredientAction extends React.Component {
  state = {
    open: false,
    ingredients: (this.props.record.ingredients &&
                  this.props.record.ingredients.fullIngredients &&
                  this.props.record.ingredients.fullIngredients !== null)
                   ? this.props.record.ingredients.fullIngredients : '',
    missingIngredients : [], 
    loading: false, 
    activeStep: 0,
    basePath: this.props.basePath || '', 
    associatedProductId: (this.props.record &&
                          this.props.record.productId &&
                          this.props.record.productId !== null ? this.props.record.productId : null), 
    associatedProductSku: (this.props.record &&
                            this.props.record.sku &&
                            this.props.record.sku !== null ? this.props.record.sku : null),
  };

  handleClickOpen = () => {
    this.setState({ open: true })
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleIngredients = (event) => {
      this.setState({ingredients: event.target.value})
  }

  showIngredients = () => {
     this.setState({open: false});
  }

  openDialog = () => {
    this.setState({open: true})
  }

  checkForMissingIngredients = async () => {
    this.setState({loading: true});
    const resource = this.props.basePath.split('/')[1];
    const ingredientResource = `ingredients-${resource.split('-')[1]}-${resource.split('-')[2]}`;
    const parsedMissingIngredients = await IngredientParse(this.state.ingredients, ingredientResource);
    this.setState({ missingIngredients: parsedMissingIngredients, loading: false, activeStep: 1});
  }

  

  render() {
    return (
      <div>
        <Button onClick={this.handleClickOpen}>Check For Missing Ingredients</Button>
        <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        fullScreen={true}
        >
        <IngredientStepper 
          handleIngredients={this.handleIngredients}
          handleClose={this.handleClose}
          checkForMissingIngredients={this.checkForMissingIngredients}
          ingredients={this.state.ingredients}
          missingIngredients={this.state.missingIngredients} 
          activeStep={this.state.activeStep}
          basePath={this.props.basePath}
          associatedProductId={this.state.associatedProductId}
          associatedProductSku={this.state.associatedProductSku}
        />
      </Dialog> 
      </div>
    );
  }
}
