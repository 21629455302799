import {
    AUTH_LOGOUT,
    AUTH_CHECK,
    AUTH_ERROR,
    AUTH_GET_PERMISSIONS,
    AUTH_LOGIN
} from 'react-admin';
import firebaseAuthInstance from '../config/firebaseAuth';
import { getUser } from './AuthConfig';

export default async (type, params) => {
    const logoutAction = () => {
        firebaseAuthInstance.auth().signOut();
        console.log('Signing out');
        return Promise.resolve({ redirectTo: '/login' });
    };

    if (type === AUTH_LOGOUT) {
        return logoutAction();
    }

    const user = await getUser();

    if (user) {
        await firebaseAuthInstance.auth().currentUser.reload();
    }

    if (type === AUTH_CHECK) {
        if (!user) {
            return logoutAction();
        }
        return Promise.resolve();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        if (!user) {
            return logoutAction();
        }
        return Promise.resolve();
    }

    if (type === AUTH_LOGIN) {
        if (!user && params && params.username && params.password) {
            await firebaseAuthInstance
                .auth()
                .signInWithEmailAndPassword(params.username, params.password);
        }
        if (await getUser()) {
            return Promise.resolve();
        } else {
            return logoutAction();
        }
    }

    if (type === AUTH_ERROR) {
        console.error('Authentication Error: ' + params.status, params);
        return logoutAction();
    }

    console.error('Undefined Authentication State');
    return logoutAction();
};
