import React from 'react';
import {
    SimpleForm,
    TextInput,
    SelectInput,
    ArrayInput,
    SimpleFormIterator,
    BooleanInput,
    DateInput
} from 'react-admin';
import NumberInput from '../components/ReactAdminCustom/NumberInput';
import { validateCategoryId } from '../validation/Category.js';
import { FormToolbar } from '../components/FormToolbar';

import { UserTypes, Visibility } from '../constants/returnTypes';

const sanitizeRestProps = ({
    anyTouched,
    array,
    asyncValidate,
    asyncValidating,
    autofill,
    blur,
    basePath,
    change,
    clearAsyncError,
    clearFields,
    clearSubmit,
    clearSubmitErrors,
    destroy,
    dirty,
    dispatch,
    form,
    handleSubmit,
    hasList,
    hasEdit,
    hasCreate,
    hasShow,
    initialize,
    initialized,
    initialValues,
    pristine,
    pure,
    redirect,
    reset,
    resetSection,
    submit,
    submitFailed,
    submitSucceeded,
    submitting,
    touch,
    translate,
    triggerSubmit,
    untouch,
    valid,
    validate,
    ...props
}) => props;

let validateCatId;

const CategoryChildrenForm = props => (
    <ArrayInput label="Children" source="children">
        <SimpleFormIterator>
            <TextInput label="Name" source="name" />
            <NumberInput
                label="ID"
                source="categoryId"
                validate={validateCatId}
            />
            <BooleanInput
                label="Only Breadcrumb"
                source="onlyBreadcrumb"
                defaultValue={false}
            />
            <SelectInput
                label="Is Active"
                source="isActive"
                choices={[
                    { id: 0, name: 'No' },
                    { id: 1, name: 'Yes' }
                ]}
            />
            <NumberInput label="Parent ID" source="parentId" />
            <NumberInput label="Level" source="level" />
            <NumberInput label="Position" source="position" />
            <TextInput label="Slug" source="slug" />
            <ArrayInput
                label="Visibility Range for User Types"
                source="userTypes"
            >
                <SimpleFormIterator>
                    <SelectInput
                        label="User Type"
                        source="userType"
                        choices={UserTypes}
                    />
                    <SelectInput
                        label="Visibility"
                        source="visibility"
                        choices={Visibility}
                    />
                    <DateInput
                        label="Visibility Start Date"
                        source="visibleDateRange.fromDate"
                    />
                    <DateInput
                        label="Visibility End Date"
                        source="visibleDateRange.toDate"
                    />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput
                label="Children"
                source="children"
                style={{ marginLeft: '25%' }}
            >
                <SimpleFormIterator>
                    <TextInput label="Name" source="name" />
                    <NumberInput
                        label="ID"
                        source="categoryId"
                        validate={validateCatId}
                    />
                    <BooleanInput
                        label="Only Breadcrumb"
                        source="onlyBreadcrumb"
                        defaultValue={false}
                    />
                    <SelectInput
                        label="Is Active"
                        source="isActive"
                        choices={[
                            { id: 0, name: 'No' },
                            { id: 1, name: 'Yes' }
                        ]}
                    />
                    <NumberInput label="Parent ID" source="parentId" />
                    <NumberInput label="Level" source="level" />
                    <NumberInput label="Position" source="position" />
                    <TextInput label="Slug" source="slug" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleFormIterator>
    </ArrayInput>
);

export const CategoryForm = props => {
    validateCatId = validateCategoryId(props.location);

    return (
        <SimpleForm {...sanitizeRestProps(props)} toolbar={<FormToolbar />}>
            <TextInput label="Name" source="name" />
            <NumberInput
                label="ID"
                source="categoryId"
                validate={validateCatId}
            />
            <BooleanInput
                label="Only Breadcrumb"
                source="onlyBreadcrumb"
                defaultValue={false}
            />
            <SelectInput
                label="Is Active"
                source="isActive"
                choices={[
                    { id: 0, name: 'No' },
                    { id: 1, name: 'Yes' }
                ]}
            />
            <NumberInput label="Parent ID" source="parentId" />
            <NumberInput label="Level" source="level" />
            <NumberInput label="Position" source="position" />
            <TextInput label="Slug" source="slug" />
            <ArrayInput
                label="Visibility Range for User Types"
                source="userTypes"
            >
                <SimpleFormIterator>
                    <SelectInput
                        label="User Type"
                        source="userType"
                        choices={UserTypes}
                    />
                    <SelectInput
                        label="Visibility"
                        source="visibility"
                        choices={Visibility}
                    />
                    <DateInput
                        label="Visibility Start Date"
                        source="visibleDateRange.fromDate"
                    />
                    <DateInput
                        label="Visibility End Date"
                        source="visibleDateRange.toDate"
                    />
                </SimpleFormIterator>
            </ArrayInput>
            <CategoryChildrenForm {...sanitizeRestProps(props)} />
        </SimpleForm>
    );
};
