import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Create,
    Edit,
    EditButton,
    Filter,
    TextInput,
} from 'react-admin'
import { IngredientForm } from '../forms/IngredientForm';
import { IngredientEditForm } from '../forms/IngredientEditForm';

const IngredientFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Ingredient ID" source="ingredientId" alwaysOn />
        <TextInput label="Name" source="title" alwaysOn/>
    </Filter>
);

export const IngredientList = (props) => (
    <List {...props} filters={<IngredientFilter />}>
        <Datagrid>
            <TextField label="ID" source="ingredientId" />
            <TextField label="Name" source="title" />
            <EditButton />
        </Datagrid>
    </List>
);

export const IngredientCreate = (props) => (
    <Create {...props} >
        <IngredientForm {...props} />
    </Create>
);

export const IngredientEdit = (props) => (
    <Edit title="Edit Ingredient" {...props} >
        <IngredientEditForm {...props} />
    </Edit>
);