import firestore from '../config/firestore';
import { randomId } from '../dataProvider/util';

const addMissingIngredientDocument = async (
    missingIngredient,
    resource,
    associatedProductId,
    associatedProductSku
) => {
    const ingredientResource = `ingredients-${resource.split('-')[1]}-${
        resource.split('-')[2]
    }`;
    const collectionRef = firestore.collection(ingredientResource);

    const ingredient = {
        title: missingIngredient,
        ingredientId: randomId(),
        associatedProductIds: [associatedProductId],
        associatedProductSkus: [`${associatedProductSku}`]
    };
    await collectionRef
        .add({ ...ingredient })
        .then(() => {
            console.info(`${ingredient.title} successfully uploaded`);
        })
        .catch(error => {
            console.error(error);
        });
};

export const uploadMissingIngredientsToFirestore = async (
    missingIngredients,
    collection,
    associatedProductId,
    associatedProductSku
) => {
    const results = [];
    missingIngredients.map(ingredient =>
        results.push(
            addMissingIngredientDocument(
                ingredient,
                collection,
                associatedProductId,
                associatedProductSku
            )
        )
    );
    Promise.all(results);
};
