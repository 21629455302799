import { fetchUtils } from 'react-admin';
import { sanitizeDataFirestore, setAuthToken } from './util';
import { processQuery } from './Utils';

const httpClient = fetchUtils.fetchJson;

export const getCategoryList = async (firestore, resource, params) => {
    const collection = firestore.collection(resource);

    console.info(params);
    return processQuery(params, collection, Object.keys(params.filter));
};

export const getCategory = async (firestore, resource, params) => {
    let category;

    await firestore
        .collection(resource)
        .doc(params.id)
        .get()
        .then(data => {
            category = data.data();
        });

    return {
        data: {
            ...category,
            id: params.id
        }
    };
};

export const createCategory = async (firestore, resource, params) => {
    let createCategoryData = params.data;

    await firestore
        .collection(resource)
        .add(createCategoryData)
        .then(() => {
            console.info('category created successfully');
        })
        .catch(error => {
            console.error(error);
        });

    return {
        data: {
            ...createCategoryData,
            id: params.id
        }
    };
};

export const updateCategory = async (firestore, resource, params) => {
    let updateCategoryData = params.data;

    await firestore
        .collection(resource)
        .doc(params.id)
        .update(updateCategoryData)
        .then(() => {
            console.info('category updated successfully');
        })
        .catch(error => {
            console.error(error);
        });

    return {
        data: { ...updateCategoryData }
    };
};

export const deleteCategory = (db, resource, params) => {
    const url = `https://firestore.googleapis.com/v1beta1/projects/${db}/databases/(default)/documents/${resource}/${params.id}`;
    const options = setAuthToken();
    options.method = 'PATCH';

    //Set isActive to 0 to disable
    params.previousData.fields.isActive.integerValue = 0;

    const payload = {
        fields: sanitizeDataFirestore(params.previousData.fields)
    };
    options.body = JSON.stringify(payload);

    return httpClient(url, options).then(response => {
        return {
            data: params
        };
    });
};
