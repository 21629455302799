import { initializeApp } from 'firebase';
import config from '.';

const instance = initializeApp(config.firebaseConfig, 'databaseInstance');

const firestore = instance.firestore();

const settings = { timestampsInSnapshots: true };
firestore.settings(settings);

instance.auth().onAuthStateChanged(firebaseUser => console.log(firebaseUser));

export { instance as firestoreFirebase };
export default firestore;
