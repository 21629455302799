export const getBackupsList = async (firestore, resource, params) => {
    const collection = firestore.collection(resource);
    const query = collection;

    const backups = await query
        .orderBy('backupDate', 'desc')
        .get()
        .then(snapshot => snapshot.docs);

    const curDocs = [];

    if (backups.length > 0) {
        // TODO: refactor/abstract this pagination stuff out
        const startIndex =
            (params.pagination.page - 1) * params.pagination.perPage;
        const endIndex =
            startIndex + params.pagination.perPage > backups.length
                ? backups.length
                : startIndex + params.pagination.perPage;

        for (let i = startIndex; i < endIndex; i++) {
            const data = backups[i].data();
            curDocs.push({ ...data, id: backups[i].id });
        }
    }

    return {
        data: curDocs,
        total: backups.length
    };
};

export const getBackup = async (firestore, resource, params) => {
    let backup;

    await firestore
        .collection(resource)
        .doc(params.id)
        .get()
        .then(data => {
            backup = data.data();
        });

    return {
        data: {
            ...backup,
            id: params.id
        }
    };
};

export const updateBackup = async (firestore, resource, params) => {
    let updatedBackupData = params.data;

    await firestore
        .collection(resource)
        .doc(params.id)
        .update(updatedBackupData)
        .then(() => {
            console.info('backup updated successfully');
        })
        .catch(error => {
            console.error(error);
        });

    return { data: { ...updatedBackupData } };
};
