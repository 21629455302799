import React, { Component } from 'react';
import {
    Button,
    CardActions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import rollingback from '../styles/rollback.gif';

const styles = {
    red: {
        color: 'red'
    },
    green: {
        color: 'teal'
    },
    padding: {
        paddingLeft: '40px'
    }
};

class PublishCatalog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            publishModalOpen: false,
            publishButtonDisabled: true,
            publishing: false,
            isPublishError: false,
            isPublishComplete: false
        };
    }

    publishClickOpen = () => {
        this.setState({
            publishModalOpen: true,
            publishButtonDisabled: false
        });
    };

    publishClickClose = () => {
        this.setState({ publishModalOpen: false, isPublishComplete: false });
    };

    publish = async () => {
        try {
            this.setState({
                publishing: true,
                publishButtonDisabled: true
            });

            const response = await fetch(
                `https://us-central1-beautycounter-prod.cloudfunctions.net/publishCatalog/publishCatalog?cacheRefresh=true`
            );

            const responseJson = await response.json();

            if (!responseJson || !responseJson.success) {
                this.setState({
                    publishButtonDisabled: true,
                    publishing: false,
                    isPublishError: true
                });

                console.error('publish error', responseJson);
            } else {
                console.info('data complete', responseJson.success);
                this.setState({
                    publishButtonDisabled: true,
                    publishing: false,
                    isPublishComplete: true
                });
            }
        } catch (error) {
            this.setState({
                publishButtonDisabled: false,
                publishing: false,
                isPublishError: true
            });

            console.error('publish error', error);
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.padding}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.publishClickOpen}
                >
                    Publish Catalog
                </Button>
                <CardActions>
                    <Dialog
                        open={this.state.publishModalOpen}
                        onClose={this.publishClickClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {'Publish Catalog Changes'}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <div>
                                    <p className={classes.red}>
                                        You are about to publish all skus marked{' '}
                                        <strong>draft false</strong> onto the
                                        production catalog. This includes all
                                        filters and categories for all regions
                                        (CA, US).
                                    </p>
                                    <p>OK to proceed?</p>
                                    {this.state.publishing && (
                                        <p className={classes.red}>
                                            Please wait...{' '}
                                            <img
                                                src={rollingback}
                                                alt="publishing..."
                                            />
                                        </p>
                                    )}
                                    {this.state.isPublishError && (
                                        <p className={classes.red}>
                                            Error publishing.
                                        </p>
                                    )}
                                    {this.state.isPublishComplete && (
                                        <p className={classes.green}>
                                            Publish complete. Please verify your
                                            changes on Beautycounter.com as soon
                                            as possible.
                                        </p>
                                    )}
                                </div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={this.state.publishing}
                                onClick={this.publishClickClose}
                                color="primary"
                            >
                                Exit
                            </Button>
                            <Button
                                disabled={this.state.publishButtonDisabled}
                                onClick={this.publish}
                                color="primary"
                                autoFocus
                            >
                                Publish!
                            </Button>
                        </DialogActions>
                    </Dialog>
                </CardActions>
            </div>
        );
    }
}

export default withStyles(styles)(PublishCatalog);
