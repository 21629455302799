import React from 'react';
import {
    SimpleForm,
    TextInput,
    TextField,
    Edit,
    Toolbar,
    SaveButton,
    CardActions,
    fetchEnd,
    fetchStart
} from 'react-admin';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    withStyles
} from '@material-ui/core';

import rollingBack from '../styles/rollback.gif';

import firebaseAuthInstance from '../config/firebaseAuth';

import { rollback } from '../rollback/rollback';

const styles = {
    pleaseWait: {
        color: 'red'
    }
};

const sanitizeRestProps = ({
    anyTouched,
    array,
    asyncValidate,
    asyncValidating,
    autofill,
    blur,
    basePath,
    change,
    clearAsyncError,
    clearFields,
    clearSubmit,
    clearSubmitErrors,
    destroy,
    dirty,
    dispatch,
    form,
    handleSubmit,
    hasList,
    hasEdit,
    hasCreate,
    hasShow,
    initialize,
    initialized,
    initialValues,
    pristine,
    pure,
    redirect,
    reset,
    resetSection,
    submit,
    submitFailed,
    submitSucceeded,
    submitting,
    touch,
    translate,
    triggerSubmit,
    untouch,
    valid,
    validate,
    ...props
}) => props;

class RollbackForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }

    handleRollback = async id => {
        const { currentUser } = firebaseAuthInstance.auth();

        this.setState({
            disabled: true,
            rollingBack: true
        });

        rollback(id, currentUser.email)
            .then(() => {
                this.setState({
                    open: false,
                    rollingBack: false
                });
            })
            .catch(e => console.error(e));
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
            disabled: false
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;

        const BackupEditActions = props => {
            const { data } = props;
            return (
                <CardActions>
                    <Button onClick={this.handleClickOpen}>Rollback</Button>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {'Rollback Haku catalogs?'}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <div>
                                    <p className={classes.pleaseWait}>
                                        You are about to rollback from backup
                                        dated <strong>{data.backupDate}</strong>{' '}
                                        labeled <strong>{data.label}</strong>{' '}
                                        into the following US and CA{' '}
                                        <strong>beautycounter-drafts</strong>{' '}
                                        collections: products, filters, and
                                        categories.
                                    </p>
                                    <p>OK to proceed?</p>
                                    {this.state.rollingBack && (
                                        <p className={classes.pleaseWait}>
                                            Please wait...{' '}
                                            <img
                                                src={rollingBack}
                                                alt="rolling back..."
                                            />
                                        </p>
                                    )}
                                </div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={this.state.disabled}
                                onClick={this.handleClose}
                                color="primary"
                            >
                                No
                            </Button>
                            <Button
                                disabled={this.state.disabled}
                                onClick={() => this.handleRollback(data.id)}
                                color="primary"
                                autoFocus
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </CardActions>
            );
        };

        const BackupEditToolbar = props => (
            <Toolbar {...props}>
                <SaveButton />
            </Toolbar>
        );
        return (
            <Edit {...this.props} actions={<BackupEditActions />}>
                <SimpleForm
                    {...sanitizeRestProps(this.props)}
                    toolbar={<BackupEditToolbar />}
                >
                    <TextField
                        label="Backup from publish event dated"
                        source="backupDate"
                    />
                    <TextInput label="Backup Label" source="label" />
                    <TextField label="Status" source="status" />
                    {this.props.record.rolledbackDate && (
                        <TextField
                            label="Rolled back date"
                            source="rolledbackDate"
                        />
                    )}
                    {this.props.record.rolledbackBy && (
                        <TextField
                            label="Rolled back by"
                            source="rolledbackBy"
                        />
                    )}
                </SimpleForm>
            </Edit>
        );
    }
}

export default withStyles(styles)(RollbackForm);
