export const environments = {
    'haku-catalog': {
        firebaseConfig: {
            apiKey: 'AIzaSyAZ8PUXDzE8NXxGhbJrcMjRRaBXzBZnO8k',
            authDomain: 'haku-catalog.firebaseapp.com',
            databaseURL: 'https://haku-catalog.firebaseio.com',
            projectId: 'haku-catalog',
            storageBucket: 'haku-catalog.appspot.com',
            messagingSenderId: '624623552646'
        },
        engineUrl: 'http://localhost:8080',
        apiUrl:
            'https://firestore.googleapis.com/v1beta1/projects/haku-catalog/databases/(default)/documents/'
    },

    'beautycounter-drafts': {
        firebaseConfig: {
            apiKey: 'AIzaSyAhBqvtMVTrJfUW9D7t8w9mPEmjwiPdobY',
            authDomain: 'beautycounter-drafts.firebaseapp.com',
            databaseURL: 'https://beautycounter-drafts.firebaseio.com',
            projectId: 'beautycounter-drafts',
            storageBucket: 'beautycounter-drafts.appspot.com',
            messagingSenderId: '774861606739'
        },
        engineUrl: 'https://nogento.beautycountertest.com',
        apiUrl:
            'https://firestore.googleapis.com/v1beta1/projects/beautycounter-drafts/databases/(default)/documents/`'
    },

    'beautycounter-prod': {
        firebaseConfig: {
            apiKey: 'AIzaSyA5Tr1PBGtBHFyDaMwXOTH0gjV6ZioSjOU',
            authDomain: 'beautycounter-prod.firebaseapp.com',
            databaseURL: 'https://beautycounter-prod.firebaseio.com',
            projectId: 'beautycounter-prod',
            storageBucket: 'beautycounter-prod.appspot.com',
            messagingSenderId: '774861606739'
        },
        engineUrl: 'https://nogento.beautycounter.com',
        apiUrl:
            'https://firestore.googleapis.com/v1beta1/projects/beautycounter-prod/databases/(default)/documents/`'
    }
};

/**
 * when useTestCollections is true, deploy to haku-test only, so as to prevent live Haku CMS from touching test data
 * when useTestCollections is false, deploy to haku-catalog only, so as to prevent test Haku CMS from touching live data
 */
const useTestCollections = false

export const catalogsConfig = {
    useTestCollections,
    enUS: {
        catalog: useTestCollections
            ? 'products-en-us-sap-test'
            : 'products-en-us',
        label: useTestCollections
            ? 'Products (En, US) Test'
            : 'Products (En, US)'
    },
    enCA: {
        catalog: useTestCollections
            ? 'products-en-ca-sap-test'
            : 'products-en-ca',
        label: useTestCollections
            ? 'Products (En, CA) Test'
            : 'Products (En, CA)'
    },
    frCA: {
        catalog: useTestCollections
            ? 'products-fr-ca-sap-test'
            : 'products-fr-ca',
        label: useTestCollections
            ? 'Products (Fr, CA) Test'
            : 'Products (Fr, CA)'
    }
};

export default environments[process.env.REACT_APP_FIREBASE];
