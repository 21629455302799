import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
} from 'react-admin';
import { CategoryForm } from '../forms/CategoryForm';

export const CategoryList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField label="ID" source="categoryId" />
            <TextField label="Name" source="name" />
            <EditButton />
        </Datagrid>
    </List>
);

export const CategoryCreate = (props) => (
    <Create {...props}>
        <CategoryForm {...props} />
    </Create>
)

export const CategoryEdit = (props) => (
    <Edit title="Edit Category"  {...props}>
        <CategoryForm {...props}/>
    </Edit>
)