import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Filter,
    TextInput,
} from 'react-admin';
import { LogForm } from '../forms/LogForm';

const LogsFilter = (props) => (
    <Filter {...props}>
        <TextField>Filter By: </TextField>
        <TextInput label="Sku" source="newSku" alwaysOn />
        <TextInput label="Updated by (email)" source="updatedByEmail" alwaysOn />
    </Filter>
)

export const LogsList = (props) => (
    <List {...props} filters={<LogsFilter />}>
        <Datagrid>
            <TextField label="Update Time (PST)" source="updateTime" />
            <TextField label="Updated by" source="updatedByEmail" />
            <TextField label="Sku" source="newSku" />
            <EditButton label="View Details" />
        </Datagrid>
    </List>
)

export const LogView = (props) => (
    <Edit title="Log Details"  {...props}>
        <LogForm {...props} />
    </Edit>
)