import { flattenDeep, chunk, cloneDeep } from 'lodash';
import firestore, { firestoreFirebase } from '../config/firestore';
import { catalogsConfig } from '../config';

var storage;

export const initializeStorage = () => {
    storage = firestoreFirebase.storage();
};

export const getDocumentID = path => {
    let pathTokens = path.split('/');
    return pathTokens[pathTokens.length - 1];
};

export const randomId = () => {
    //Min inclusive, Max exclusive
    const min = Math.ceil(100000);
    const max = Math.floor(10000000);
    return Math.floor(Math.random() * (max - min)) + min;
};

export const generateSwatchIds = productSwatch => {
    if (productSwatch.attributeId === undefined) {
        productSwatch.attributeId = randomId();
    }
    if (productSwatch.optionId === undefined) {
        productSwatch.optionId = randomId();
    }
    if (productSwatch.entityId === undefined) {
        productSwatch.entityId = randomId();
    }
};

export const sanitizeProductBundledOptions = productBundledOptions => {
    if (productBundledOptions.length) {
        productBundledOptions.map(bundledOptionValue => {
            if (bundledOptionValue.bundledOptionValues) {
                bundledOptionValue.bundledOptionValues.map(value => {
                    if (value.fmfImage && value.fmfImage.fmfImage) {
                        value.fmfImage = value.fmfImage['fmfImage'];
                    }
                    if (
                        value.fmfImageReview &&
                        value.fmfImageReview.fmfImageReview
                    ) {
                        value.fmfImageReview =
                            value.fmfImageReview['fmfImageReview'];
                    }
                    if (
                        value.swatchGraphic &&
                        value.swatchGraphic.swatchGraphic
                    ) {
                        value.swatchGraphic =
                            value.swatchGraphic['swatchGraphic'];
                    }
                });
            }
        });
    }
};

export const sanitizeVariantImages = variants => {
    (variants || []).map(variant => {
        // if (variant.image && variant.image.imageUrl) {
        //     variant.image.imageUrl = variant.image.imageUrl['imageUrl'];
        // }

        if (variant.image && variant.image.gallery) {
            variant.image.gallery.map(galleryImageProperties => {
                if (galleryImageProperties.url.url) {
                    galleryImageProperties.url =
                        galleryImageProperties.url['url'];
                }
            });
        }
        if (variant.swatches && variant.swatches.swatchImage) {
            if (variant.swatches.swatchImage.swatchImage) {
                variant.swatches.swatchImage =
                    variant.swatches.swatchImage['swatchImage'];
            }
            if (variant.swatches.rawFile) {
                variant.swatches.rawFile = '';
            }
        }
        if (variant.productId == undefined) {
            variant.productId = randomId();
        }
        if (variant.swatches) {
            generateSwatchIds(variant.swatches);
        }
    });
};

export const sanitizeGalleryImages = gallery => {
    gallery.map(galleryImageProperties => {
        if (galleryImageProperties.url.url) {
            galleryImageProperties.url = galleryImageProperties.url['url'];
        }
    });
};

/**
 * Summary: Flatten array to match Firestore schema.
 * @param {Array} property
 */

export const flattenArrayProperties = property => {
    let flatArray = [];
    property.map(propertyValue => {
        flatArray.push(Object.values(propertyValue));
    });
    let flattenedArray = flattenDeep(flatArray);
    return flattenedArray;
};

/**
 * Summary: Format arry to match Firestore format as [""45": 34"].
 * @param {Array} positionsArray - Flat array from flattenArrayProperties
 */
export const formatPositionsArray = positionsArray => {
    let flatPositionArray = [];
    let chuckArrayByCategoryandPosition = chunk(positionsArray, 2);
    chuckArrayByCategoryandPosition.map(position => {
        flatPositionArray.push(position.join(' '));
    });
    let formattedArray = [];
    flatPositionArray.map(position => {
        const temp = position.split('');
        for (let index in temp) {
            if (temp[index] == ' ') {
                temp[index] = ':';
            }
        }
        formattedArray.push(temp.join(''));
    });
    return formattedArray;
};

//SelectArrayInput only expects strings for valur
export const sanitizeSelectArrayInputValues = (item, data) => {
    return JSON.parse(JSON.stringify(data), (key, value) => {
        if (key === item && value.arrayValue && value.arrayValue.values) {
            var expectedValues = [];
            for (var i = 0; i < value.arrayValue.values.length; i++) {
                expectedValues.push(value.arrayValue.values[i].stringValue);
            }

            return { arrayValue: { values: expectedValues } };
        }

        return value;
    });
};

export const sanitizeDataFirestore = data => {
    return JSON.parse(JSON.stringify(data), (key, value) => {
        //Remove empty array objects
        if (key === 'arrayValue' && value.hasOwnProperty('values')) {
            var content = [];
            for (var i = 0; i < value.values.length; i++) {
                if (Object.keys(value.values[i]).length !== 0) {
                    content.push(value.values[i]);
                }
            }
            return { values: content };
        }

        //Ensure all strings are attached to a stringValue key
        if (
            typeof value === 'string' &&
            key !== 'stringValue' &&
            key !== 'integerValue' &&
            key !== 'booleanValue'
        ) {
            return { stringValue: value };
        }

        //Remove all instances of a rawfile
        //will have already been uploaded to storage
        if (value && value.hasOwnProperty('rawFile')) {
            delete value.rawFile;
        }

        return value;
    });
};

export const setAuthToken = token => {
    const options = {};

    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    token = token || localStorage.getItem('accessToken');
    options.headers.set('Authorization', `Bearer ${token}`);

    return options;
};

export const uploadFirestoreImage = async (sku, image, path) => {
    const ref = storage
        .ref()
        .child(`product-images/${sku}/${path}/${image.rawFile.name}`);
    const snapshot = await ref.put(image.rawFile);
    const getDownloadURL = await snapshot.ref.getDownloadURL();
    const pictureUrl = getDownloadURL.split('?').shift() + '?alt=media';
    return pictureUrl;
};

export const uploadFirestoreImages = async (sku, image, swatches, path) => {
    if (image) {
        const imageVal = image;

        // Upload new image
        if (imageVal && imageVal.imageUrl && imageVal.imageUrl.rawFile) {
            const url = await uploadFirestoreImage(
                sku,
                imageVal.imageUrl,
                path + 'imgs'
            );
            imageVal.rawFile = '';
            imageVal.imageUrl = url;
            imageVal.smallImageUrl = url;
            imageVal.thumbnailUrl = url;
        }

        if (
            imageVal &&
            imageVal.bundledSkuInterruptImageUrl &&
            imageVal.bundledSkuInterruptImageUrl.rawFile
        ) {
            imageVal.bundledSkuInterruptImageUrl = await uploadFirestoreImage(
                sku,
                imageVal.bundledSkuInterruptImageUrl,
                path + 'imgs/bundled-image'
            );
        }

        // Upload new images in gallery
        if (
            imageVal.gallery &&
            imageVal.gallery &&
            imageVal.gallery.length > 0
        ) {
            const { gallery } = imageVal;

            for (let i = 0; i < gallery.length; i++) {
                if (gallery[i] && gallery[i].url && gallery[i].url.rawFile) {
                    gallery[i].url = await uploadFirestoreImage(
                        sku,
                        gallery[i].url,
                        path + 'gallery/imgs'
                    );
                }
            }
        }
    }

    //Upload new swatch image
    if (swatches && swatches.swatchImage && swatches.swatchImage.rawFile) {
        swatches.swatchImage = await uploadFirestoreImage(
            sku,
            swatches.swatchImage,
            path + 'swatches/imgs'
        );
    }
};

/**
 * USED WITH REST CALLS - Create Product.
 */
export const uploadImage = async (sku, image, path) => {
    const ref = storage
        .ref()
        .child(`product-images/${sku}/${path}/${image.rawFile.name}`);
    const snapshot = await ref.put(image.rawFile);

    return await snapshot.ref
        .getDownloadURL()
        .then(downloadURL => downloadURL.split('?').shift() + '?alt=media');
};

export const uploadImages = async (sku, image, swatches, path) => {
    if (image && image.mapValue) {
        const imageVal = image.mapValue.fields;

        //Upload new image
        if (imageVal.imageUrl && imageVal.imageUrl.rawFile) {
            const url = await uploadImage(
                sku,
                imageVal.imageUrl,
                path + 'imgs'
            );
            imageVal.imageUrl.stringValue = url;
            imageVal.smallImageUrl = { stringValue: url };
            imageVal.thumbnailUrl = { stringValue: url };
        }

        //Upload new images in gallery
        if (
            imageVal.gallery &&
            imageVal.gallery.arrayValue &&
            imageVal.gallery.arrayValue.values.length > 0
        ) {
            const gallery = imageVal.gallery.arrayValue.values;

            for (var i = 0; i < gallery.length; i++) {
                if (
                    gallery[i].mapValue &&
                    gallery[i].mapValue.fields.url &&
                    gallery[i].mapValue.fields.url.rawFile
                ) {
                    gallery[
                        i
                    ].mapValue.fields.url.stringValue = await uploadImage(
                        sku,
                        gallery[i].mapValue.fields.url,
                        path + 'gallery/imgs'
                    );
                }
            }
        }
    }

    //Upload new swatch image
    if (
        swatches &&
        swatches.mapValue &&
        swatches.mapValue.fields.swatchImage &&
        swatches.mapValue.fields.swatchImage.rawFile
    ) {
        swatches.mapValue.fields.swatchImage.stringValue = await uploadImage(
            sku,
            swatches.mapValue.fields.swatchImage,
            path + 'swatches/imgs'
        );
    }
};

export const uploadVariantImages = async (sku, variants) => {
    if (variants && variants.length > 0) {
        for (let variant = 0; variant < variants.length; variant++) {
            if (variants[variant]) {
                await uploadFirestoreImages(
                    sku,
                    variants[variant].image,
                    variants[variant].swatches,
                    `variants/${variants[variant].sku}/`
                );
            }
        }
    }
};

//Upload new bundle detail images in bundle options
export const uploadBundledOptions = async (sku, bundledOptions) => {
    if (bundledOptions && bundledOptions.length > 0) {
        const options = bundledOptions;

        for (var i = 0; i < options.length; i++) {
            const values = options[i].bundledOptionValues;

            for (var j = 0; j < values.length; j++) {
                if (values[j]) {
                    const curVal = values[j];

                    if (curVal.fmfImage && curVal.fmfImage.rawFile) {
                        curVal.fmfImage = await uploadFirestoreImage(
                            sku,
                            curVal.fmfImage,
                            'bundles/imgs'
                        );
                    }

                    if (
                        curVal.fmfImageReview &&
                        curVal.fmfImageReview.rawFile
                    ) {
                        curVal.fmfImageReview = await uploadFirestoreImage(
                            sku,
                            curVal.fmfImageReview,
                            'bundles/imgs'
                        );
                    }

                    if (curVal.swatchGraphic && curVal.swatchGraphic.rawFile) {
                        curVal.swatchGraphic = await uploadFirestoreImage(
                            sku,
                            curVal.swatchGraphic,
                            'bundles/imgs'
                        );
                    }
                }
            }
        }
    }
};

export const ensureIntegersforDropdowns = (data, field) => {
    if (data.fields[field]) {
        if (data.fields[field]['integerValue']) {
            //Firebase returns integers in string form
            data.fields[field]['integerValue'] = parseInt(
                data.fields[field]['integerValue'],
                10
            );
        }
        //Can be removed once all occurences of 'field'
        //is a number and not a string
        else if (data.fields[field]['stringValue']) {
            data.fields[field]['integerValue'] = parseInt(
                data.fields[field]['stringValue'],
                10
            );
            delete data.fields[field]['stringValue'];
        }
    }
};

export const setCustomSize = payload => {
    if (
        payload.fields.size &&
        payload.fields.size.stringValue !== 'travel' &&
        payload.fields.size.stringValue !== 'sample'
    ) {
        payload.fields.size.custom = payload.fields.size.stringValue;
        payload.fields.size.stringValue = 'custom';
    }
};

export const sanitizeSizeData = data => {
    if (data.fields.size && data.fields.size.stringValue === 'custom') {
        data.fields.size.stringValue = data.fields.size.custom;
        delete data.fields.size.custom;
    } else if (data.fields.size && data.fields.size.custom) {
        delete data.fields.size.custom;
    }
};

export const setPositionData = payload => {
    if (
        payload.fields.positions &&
        payload.fields.positions.arrayValue &&
        payload.fields.positions.arrayValue.values.length > 0
    ) {
        const positions = payload.fields.positions.arrayValue.values;

        for (var i = 0; i < positions.length; i++) {
            const CATEGORY = 0;
            const POSITION = 1;
            var positionFields = positions[i].stringValue.split(':');

            positions[i] = {
                category: positionFields[CATEGORY].replace(/['"]+/g, ''),
                position: positionFields[POSITION]
            };
        }
    }
};

export const sanitizePositions = data => {
    if (
        data.fields.positions &&
        data.fields.positions.arrayValue &&
        data.fields.positions.arrayValue.values.length > 0
    ) {
        const positions = data.fields.positions.arrayValue.values;
        for (var i = 0; i < positions.length; i++) {
            var position =
                '"' + positions[i].category + '":' + positions[i].position;
            positions[i] = { stringValue: position };
        }
    }
};

export const getProductRegionLinks = async (curRegion, sku) => {
    const regions = ['products-en-us', 'products-en-ca', 'products-fr-ca'];
    const links = [];

    const addLink = snapshot => {
        if (snapshot.docs[0]) {
            links.push(`#/${regions[i]}/${snapshot.docs[0].id}`);
        }
    };

    for (var i = 0; i < regions.length; i++) {
        if (curRegion !== regions[i]) {
            const collectionRef = firestore.collection(regions[i]);
            const query = collectionRef.where('sku', '==', sku);

            await query.get().then(addLink);
        }
    }

    return links;
};

export const sanitizeEmptyStrings = (data, field, defaultValue) => {
    if (data.fields[field] && !data.fields[field]['booleanValue']) {
        if (data.fields[field]['stringValue'] === '') {
            data.fields[field] = { booleanValue: defaultValue };
        }
    }
};

export const sanitizeIntegers = data => {
    return JSON.parse(JSON.stringify(data), (key, value) => {
        if (key === 'integerValue') {
            return parseInt(value, 10);
        }
        return value;
    });
};

export const sanitizeGetItemData = docs => {
    if (docs && docs.size && docs.size === 'simple') {
        docs.size = docs.size['size'];
        docs.custom = docs.size['custom'];
    }

    if (docs && docs.image && docs.image.gallery) {
        docs.image.gallery.map(imageItem => {
            if (imageItem.url) {
                imageItem.url = { url: imageItem.url };
            }
        });
    }

    if (docs && docs.variants) {
        (docs.variants || []).map(variant => {
            if (variant.swatches && variant.swatches.swatchImage) {
                variant.swatches.swatchImage = {
                    swatchImage: variant.swatches.swatchImage
                };
            }
            if (variant.image && variant.image.gallery) {
                variant.image.gallery.map(imageItem => {
                    if (imageItem.url) {
                        imageItem.url = { url: imageItem.url };
                    }
                });
            }
        });
    }

    if (docs && docs.bundledOptions) {
        docs.bundledOptions.map(bundledOptionValue => {
            if (bundledOptionValue.bundledOptionValues) {
                bundledOptionValue.bundledOptionValues.map(value => {
                    if (value.fmfImage) {
                        value.fmfImage = { fmfImage: value.fmfImage };
                    }
                    if (value.fmfImageReview) {
                        value.fmfImageReview = {
                            fmfImageReview: value.fmfImageReview
                        };
                    }
                    if (value.swatchGraphic) {
                        value.swatchGraphic = {
                            swatchGraphic: value.swatchGraphic
                        };
                    }
                });
            }
        });
    }
};

export const generateProductIdForBundledOptions = bundledOptions => {
    if (bundledOptions) {
        bundledOptions.map(bundledOptionValues => {
            if (bundledOptionValues.bundledOptionValues) {
                bundledOptionValues.bundledOptionValues.map(value => {
                    if (
                        value.productId === undefined ||
                        value.productId === null
                    ) {
                        value.productId = randomId();
                    }
                });
            }
        });
    }
};

export const uploadVariationToFirestore = async (
    variationResource,
    data,
    firestore
) => {
    await firestore
        .collection(variationResource)
        .add(data)
        .then(() => {
            console.info('document successfully created');
        })
        .catch(error => {
            console.error(error);
        });
};

export const saveAcrossDrafts = async (product, displayName) => {
    const skuToUpdate = product.sku;
    // remove fields, that dont need to be updated
    delete product.title
    delete product.productVolume
    delete product.type;
    delete product.langaugeVariance;
    delete product.upc;
    delete product.sku;
    delete product.barcode;
    delete product.googleProductCategory;
    delete product.howToUse;
    delete product.inventoryQuantity;
    delete product.lastSAPUpdateEvent;
    delete product.metaDescription;
    delete product.metaTitle
    delete product.metaKeywords
    delete product.safetyFirstMessage;
    delete product.shortDescription;
    delete product.simpleDescription;
    delete product.description;
    delete product.howToUse;
    delete product.simpleDescription;
    delete product.information;
    delete product.inStock;
    delete product.price;
    delete product.priceInCents;
    delete product.PV;
    delete product.QV;
    delete product.CV;
    delete product.SV;
    delete product.hostValue;
    delete product.wholesalePrice;
    delete product.taxClass;
    delete product.specialPrice;
    delete product.specialPriceFrom;
    delete product.specialPriceTo;
    delete product.groupPrices;
    delete product.altPromotion;
    delete product.promoName;
    delete product.disableProductDetailPage;
    delete product.stockNotify;
    delete product.bundledOptions;
    delete product.positions;
    delete product.customOOS;
    delete product.bestFor;
    delete product.productVariety;
    delete product.metadata;
    delete product.value;
    delete product.label;
    delete product.whatsInside;
    delete product.autoFlipTaskID;
    delete product.saveAcrossLocales;
    delete product.altVideo
    delete product.video
    delete product.isFinalSaleConfig

    if (product.variants) {
        for (let variant of product.variants) {
            delete variant.title
            delete variant.productVolume
            delete variant.barcode;
            delete variant.upc;
            delete variant.inventoryQuantity;
            delete variant.lastSAPUpdateEvent;
            delete variant.altVideo;
            delete variant.description;
            delete variant.metaTitle
            delete variant.metaKeywords
            delete variant.information
            delete variant.productId;
            delete variant.howToUse;
            delete variant.isFinalSaleConfig;
            delete variant.metaDescription;
            delete variant.newFrom;
            delete variant.newTo;
            delete variant.positions;
            delete variant.hostValue;
            delete variant.googleProductCategory
            delete variant.PV;
            delete variant.QV;
            delete variant.CV;
            delete variant.SV;
            delete variant.price;
            delete variant.wholesalePrice;
            delete variant.priceInCents;
            delete variant.simpleDescription;
            delete variant.specialPrice;
            delete variant.specialPriceFrom;
            delete variant.specialPriceTo;
            delete variant.video;
            delete variant.bestFor;
            delete variant.productVariety;
            delete variant.video
        }
    }
    let docIds = [];
    const en_us = catalogsConfig.useTestCollections ? 'products-en-us-sap-test' : 'products-en-us';
    const en_ca = catalogsConfig.useTestCollections ? 'products-en-ca-sap-test' : 'products-en-ca';
    const fr_ca = catalogsConfig.useTestCollections ? 'products-fr-ca-sap-test' : 'products-fr-ca';
    await Promise.all(
        [
            firestore
                .collection(en_us)
                .where('sku', '==', skuToUpdate.toString())
                .get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        docIds.push({ docId: doc.id, collection: en_us, data: doc.data() })
                    })
                }),
            firestore
                .collection(en_ca)
                .where('sku', '==', skuToUpdate.toString())
                .get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        docIds.push({ docId: doc.id, collection: en_ca, data: doc.data() })
                    })
                }),
            firestore
                .collection(fr_ca)
                .where('sku', '==', skuToUpdate.toString())
                .get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        docIds.push({ docId: doc.id, collection: fr_ca, data: doc.data() })
                    })
                })
        ]
    ).then(() => {
        console.info('docIds found');
    })
        .catch(error => {
            console.log('error, error')
            console.error(error);
        })

    // to not overwrite any nested objects in arrays
    for (let doc of docIds) {
        const { data } = doc;

        for (let key in product) {
            if (key === 'variants' && product['variants']) {
                for (let item of product.variants) {
                    const { sku } = item;
                    const index = data.variants.findIndex(variant => variant.sku === sku);

                    if (index !== -1) {
                        let copy = cloneDeep(data.variants[index]);
                        data.variants[index] = { ...copy, ...item };
                    }
                }

            } else {
                data[key] = product[key];
            }
        }
    }

    await Promise.all(
        docIds.map(async doc => {
            const { docId, collection, data } = doc;
            data.lastEditedBy = displayName;

            console.info(docId, collection, data)

            await firestore
                .collection(collection)
                .doc(docId.toString())
                .set(data, { merge: true });
        })
    ).then(() => {
        console.info('docs successfully updated');
    }).catch(error => {
        console.error(error);
    })
}
