import React, { Component } from 'react';
import { Toolbar, SaveButton, crudCreate, crudUpdate } from 'react-admin';

import {
    CardActions,
    DialogContentText,
    DialogActions,
    DialogTitle,
    DialogContent,
    Dialog,
    Button
} from '@material-ui/core';

import { connect } from 'react-redux';
import { catalogsConfig } from '../config';

const buttonStyle = {
    marginLeft: '12px'
};

const publishToProd = (
    values,
    basePath,
    redirectTo,
    resource,
    previousData,
    isCreate,
    simulate
) => {
    const { id: recordId } = previousData;

    values = { ...values, isPublishToProd: !simulate, doNotSetUnmerge: true, unMergedChanges: false, testUnMerged: false };

    if (isCreate) {
        return crudCreate(resource, values, basePath, redirectTo);
    }

    return crudUpdate(
        resource,
        recordId,
        values,
        previousData,
        basePath,
        redirectTo
    );
};

const saveAcrossDrafts = (
    values,
    basePath,
    redirectTo,
    resource,
    previousData,
) => {
    const { id: recordId } = previousData;
    values = {
        ...values,
        isPublishToProd: false,
        unMergedChanges: true,
        saveAcrossLocales: true
    };

    return crudUpdate(
        resource,
        recordId,
        values,
        previousData,
        basePath,
        redirectTo
    );
};

const saveToDrafts = (values, basePath, redirectTo, resource, previousData) => {
    const { id: recordId } = previousData;
    values = { ...values, isPublishToProd: false, doNotSetUnmerge: true };

    return crudUpdate(
        resource,
        recordId,
        values,
        previousData,
        basePath,
        redirectTo
    );
};

class PublishSku extends Component {
    constructor(props) {
        super(props);

        this.state = {
            publishModalOpen: false
        };
    }

    publishClickOpen = () => this.setState({ publishModalOpen: true });

    publishClickClose = () => this.setState({ publishModalOpen: false });

    handleClick = () => {
        const {
            basePath,
            handleSubmit,
            redirect,
            publishToProd,
            resource,
            isCreate,
            record: previousData,
            simulate
        } = this.props;
        return handleSubmit(values => {
            publishToProd(
                values,
                basePath,
                redirect,
                resource,
                previousData,
                isCreate,
                simulate
            );
        });
    };

    render() {
        const {
            handleSubmitWithRedirect,
            publishToProd,
            record,
            ...props
        } = this.props;

        const { autoFlipDateUTC } = record;
        const localeDate = new Date(autoFlipDateUTC).toLocaleString();

        return (
            <React.Fragment>
                <Button
                    color="primary"
                    style={buttonStyle}
                    variant="raised"
                    onClick={this.publishClickOpen}
                >
                    {this.props.label}
                </Button>
                <CardActions>
                    <Dialog open={this.state.publishModalOpen}>
                        <DialogTitle>
                            {'Warning: Publishing Sku to Production'}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <div>
                                    <p>
                                        This operation will publish the sku up
                                        to the production environment, which
                                        will also clear the BCCOM catalog CDN.{' '}
                                        <strong>
                                            Doing this often in the day may
                                            affect site performance.
                                        </strong>
                                    </p>

                                    {autoFlipDateUTC && (
                                        <p>
                                            This SKU currently has an auto
                                            publish date on {localeDate}. This
                                            will clear scheduled automated
                                            publishing date and publish now.
                                        </p>
                                    )}

                                    <p>OK to proceed?</p>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.publishClickClose}>
                                Exit
                            </Button>
                            <SaveButton
                                handleSubmitWithRedirect={this.handleClick}
                                {...props}
                            />
                        </DialogActions>
                    </Dialog>
                </CardActions>
            </React.Fragment>
        );
    }
}

class SaveAcrossDraftLocales extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saveAcrossLocalesModal: false
        };
    }

    saveClickOpen = () => this.setState({ saveAcrossLocalesModal: true });

    saveClickClose = () => this.setState({ saveAcrossLocalesModal: false });

    handleClick = () => {
        const {
            basePath,
            handleSubmit,
            redirect,
            saveAcrossDrafts,
            resource,
            record: previousData,
            saveAcrossLocales
        } = this.props;

        return handleSubmit(values => {
            saveAcrossDrafts(
                values,
                basePath,
                redirect,
                resource,
                previousData,
                saveAcrossLocales
            );
        });
    };

    render() {
        const {
            handleSubmitWithRedirect,
            saveAcrossDrafts,
            ...props
        } = this.props;

        const mainTabFields = ['Type', 'Language Variance', 'UPC', 'SKU'];
        const textTabFields = [
            'Handle',
            'Full Ingredients',
            'Active Ingredients',
            'Key Ingredients',
            'After Ingerdients',
            'Not Crawlable'
        ];
        const imagesAndVideosTab = ['Image Drop', 'Alt Test', 'Gallery'];
        const variantsTab = ['Sort Order', 'Auto Ship', 'Size'];
        const bundlesTab = [
            'Option ID',
            'images',
            'Selection ID',
            'Swatch Graphic',
            'FMS Image',
            'Product IDs'
        ];

        return (
            <React.Fragment>
                <Button
                    color="primary"
                    style={buttonStyle}
                    variant="raised"
                    onClick={this.saveClickOpen}
                >
                    {this.props.label}
                </Button>
                <CardActions>
                    <Dialog open={this.state.saveAcrossLocalesModal}>
                        <DialogTitle>
                            {
                                'Warning: Saving SKU changes in Drafts across all locales.'
                            }
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <div>
                                    <p>
                                        This operation will only save the SKU
                                        changes listed below to all locales in
                                        Drafts.{' '}
                                        <strong>
                                            You must manually revert the
                                            changes.
                                        </strong>
                                    </p>
                                    <ul>
                                        Everything in Main Tab{' '}
                                        <strong>EXCEPT</strong>:
                                        {mainTabFields.map(el => (
                                            <li>{el}</li>
                                        ))}
                                    </ul>
                                    <ul>
                                        Text Tab
                                        {textTabFields.map(el => (
                                            <li>{el}</li>
                                        ))}
                                    </ul>
                                    <ul>
                                        Images Tab
                                        {imagesAndVideosTab.map(el => (
                                            <li>{el}</li>
                                        ))}
                                    </ul>
                                    <ul>
                                        Categories Tab
                                        <li>Everthing</li>
                                    </ul>
                                    <ul>
                                        Variants Tab
                                        {variantsTab.map(el => (
                                            <li>{el}</li>
                                        ))}
                                    </ul>
                                    <ul>
                                        Bundles Tab
                                        {bundlesTab.map(el => (
                                            <li>{el}</li>
                                        ))}
                                    </ul>

                                    <p>OK to proceed?</p>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.saveClickClose}>Exit</Button>
                            <SaveButton
                                handleSubmitWithRedirect={this.handleClick}
                                {...props}
                            />
                        </DialogActions>
                    </Dialog>
                </CardActions>
            </React.Fragment>
        );
    }
}

class SaveToDraftsWithAutoFLip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            autoFlipModalToggle: false
        };
    }

    saveClickOpen = () => this.setState({ autoFlipModalToggle: true });

    saveClickClose = () => this.setState({ autoFlipModalToggle: false });

    handleClick = () => {
        const {
            basePath,
            handleSubmit,
            redirect,
            resource,
            saveToDrafts,
            record: previousData
        } = this.props;

        return handleSubmit(values => {
            saveToDrafts(values, basePath, redirect, resource, previousData);
        });
    };

    render() {
        const { handleSubmitWithRedirect, record, ...props } = this.props;

        const { autoFlipDateUTC } = record;
        const localeDate = new Date(autoFlipDateUTC).toLocaleString();

        const defaultText = `This SKU is scheduled to publish on ${localeDate}. 
            These new changes will be committed upon the automated publish scheduled date`;

        const overrideText = `Changing either the [Status] or [Drafts] field will override the scheduled SKU, 
            and clear the calendar date. Are you sure you want to move forward?`;

        return (
            <React.Fragment>
                <Button
                    color="primary"
                    style={buttonStyle}
                    variant="raised"
                    onClick={this.saveClickOpen}
                >
                    {this.props.label}
                </Button>
                <CardActions>
                    <Dialog open={this.state.autoFlipModalToggle}>
                        <DialogTitle>
                            {
                                'Warning: This SKU is already scheduled to publish.'
                            }
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <p>{defaultText}</p>
                                <p>{overrideText}</p>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.saveClickClose}>Exit</Button>
                            <SaveButton
                                handleSubmitWithRedirect={this.handleClick}
                                {...props}
                            />
                        </DialogActions>
                    </Dialog>
                </CardActions>
            </React.Fragment>
        );
    }
}

const SaveAcrossLocalesButton = connect(undefined, { saveAcrossDrafts })(
    SaveAcrossDraftLocales
);
const PublishSkuButton = connect(undefined, { publishToProd })(PublishSku);
const SaveToDraftsWithAutoFLipButton = connect(undefined, { saveToDrafts })(
    SaveToDraftsWithAutoFLip
);

export const FormToolbar = props => {
    const { record } = props;
    const { autoFlipDateUTC } = record;

    return (
        <Toolbar {...props}>
            {autoFlipDateUTC ? (
                <SaveToDraftsWithAutoFLipButton
                    label="Save to Drafts and Continue"
                    redirect={false}
                    submitOnEnter={false}
                />
            ) : (
                <SaveButton
                    label="Save to Drafts and Continue"
                    redirect={false}
                    submitOnEnter={false}
                />
            )}

            <SaveButton
                label="Save to Drafts and Exit"
                redirect="list"
                submitOnEnter={false}
                style={buttonStyle}
            />
            <SaveAcrossLocalesButton
                label="Save to Drafts for All Locales"
                redirect="list"
                submitOnEnter={false}
                saveAcrossLocales={true}
            />
            <PublishSkuButton
                label={`${catalogsConfig.useTestCollections ? 'Simulate ' : ''
                    }Save to Drafts and Publish`}
                redirect="list"
                submitOnEnter={false}
                isCreate={props.isCreate}
                simulate={catalogsConfig.useTestCollections}
            />
        </Toolbar>
    );
};
