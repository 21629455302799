import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


export const IngredientActionForm = ({ingredients,checkForMissingIngredients, handleIngredients, handleClose}) => {
    return (
        <div>
        <DialogTitle id="form-dialog-title">Please enter a set of ingredients. </DialogTitle>
        <DialogContent>
            <TextField
            autoFocus
            multiline
            margin="dense"
            label="Ingredients"
            fullWidth={true}
            value={ingredients}
            onChange={(e) => handleIngredients(e)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cancel
            </Button>
            <Button onClick={checkForMissingIngredients} color="primary">
            Missing Ingredients
            </Button>
        </DialogActions>
        </div>
    )
}

IngredientActionForm.protoTypes = {
    ingredients: PropTypes.array, 
    checkForMissingIngredients: PropTypes.func, 
    handleIngredients: PropTypes.func, 
    handleClose: PropTypes.func
}