/* eslint-disable react/prop-types */
// Variant form on Product CREATE
import React from 'react';
import {
    TextInput,
    TextField,
    LongTextInput,
    ArrayInput,
    SimpleFormIterator,
    BooleanInput,
    DateInput,
    ImageInput,
    ImageField,
    SelectInput,
    required
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import NumberInput from './ReactAdminCustom/NumberInput';
import { ProductVariantImage } from './ProductImages';
import { Sizes, FinalSaleTypes, Statuses } from '../constants/returnTypes';
import {
    validateSKU,
    validateStringPricing,
    validateNumberPricing,
    volumeErrorMsg,
    validateProdId
} from '../validation/Product';
import AutoShipSettingsComponent from './AutoshipSettingsComponent';

const styles = theme => ({
    details: {
        width: 500,
        display: 'inline-grid'
    }
});

const variantValidation = () => false;

const VariantEdit = props => {
    const { source, classes, location } = props;

    return (
        <ExpansionPanel>
            <ExpansionPanelSummary>
                <TextInput
                    label="Title"
                    source={`${source}.title`}
                    style={{ width: '80%' }}
                    validate={required()}
                />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
                <TextInput
                    label="Swatch Label"
                    source={`${source}.swatches.swatchlabel`}
                    validate={variantValidation()}
                />
                <TextInput
                    label="Swatch Hex Code"
                    source={`${source}.swatches.colorHexCode`}
                    validate={variantValidation()}
                />
                <ImageInput
                    label="Swatch Image or Icon"
                    source={`${source}.swatches.swatchImage`}
                >
                    <ImageField
                        source="swatchImage"
                        validate={variantValidation()}
                    />
                </ImageInput>
                <TextInput
                    label="Swatch Alt Text"
                    source={`${source}.swatches.altText`}
                />
                <TextInput
                    label="Swatch Position"
                    source={`${source}.swatches.position`}
                    validate={required()}
                />

                <TextInput label="Alt Video" source={`${source}.altVideo`} />
                <BooleanInput
                    label="Best Seller"
                    source={`${source}.bestSeller`}
                />
                <ArrayInput label="Categories" source={`${source}.categories`}>
                    <SimpleFormIterator>
                        <NumberInput label="Category Number" source="" />
                    </SimpleFormIterator>
                </ArrayInput>
                <BooleanInput
                    label="Draft"
                    source={`${source}.draft`}
                    defaultValue={true}
                />
                <BooleanInput
                    label="In Stock"
                    source={`${source}.inStock`}
                    defaultValue={true}
                />
                <BooleanInput
                    label="Do Not Ship Alone"
                    source={`${source}.doNotShipAlone`}
                    defaultValue={false}
                />
                <LongTextInput
                    label="Description"
                    source={`${source}.description`}
                />
                <TextInput
                    label="Handle"
                    source={`${source}.handle`}
                    validate={required()}
                />
                <LongTextInput
                    label="How To Use"
                    source={`${source}.howToUse`}
                />
                <ProductVariantImage {...props} />
                <ArrayInput label="Gallery" source={`${source}.image.gallery`}>
                    <SimpleFormIterator>
                        <ImageInput label="Image Url" source="url">
                            <ImageField source="url" />
                        </ImageInput>
                        <TextInput
                            label="Image Alt Text"
                            source={`${source}.altText`}
                        />
                        <BooleanInput
                            label="Disabled"
                            source={`${source}.disabled`}
                        />
                        <NumberInput
                            label="Sort Order"
                            source={`${source}.sortOrder`}
                        />
                        <ImageField label="URL" src={`${source}.url.url`} />
                    </SimpleFormIterator>
                </ArrayInput>
                <SelectInput
                    label="Is Final Sale Config"
                    source={`${source}.isFinalSaleConfig`}
                    choices={FinalSaleTypes}
                />
                <BooleanInput
                    label="Is GWP"
                    source={`${source}.isGWP`}
                    defaultValue={false}
                />
                <BooleanInput
                    label="Is Virtual"
                    source={`${source}.isVirtual`}
                />
                <BooleanInput
                    label="Is Shippable"
                    source={`${source}.isShippable`}
                    defaultValue={true}
                />
                <BooleanInput
                    label="Hide From Enrolling Consultant"
                    source={`${source}.hideFromEnrollingConsultant`}
                />
                <LongTextInput
                    label="Full Ingredients"
                    source={`${source}.ingredients.fullIngredients`}
                />
                <LongTextInput
                    label="Key Ingredients"
                    source={`${source}.ingredients.keyIngredients`}
                />
                <LongTextInput
                    label="Active Ingredients"
                    source={`${source}.ingredients.activeIngredients`}
                />
                <ArrayInput
                    label="After Ingredients"
                    source={`${source}.ingredients.afterIngredients`}
                >
                    <SimpleFormIterator>
                        <TextInput label="After Ingredient" source="" />
                    </SimpleFormIterator>
                </ArrayInput>
                <NumberInput
                    label="Max Quantity"
                    source={`${source}.maxQuantity`}
                    defaultValue={10}
                    isRequired={true}
                />
                <LongTextInput
                    label="Meta Description"
                    source={`${source}.metaDescription`}
                />
                <DateInput label="New From" source={`${source}.newFrom`} />
                <DateInput label="New To" source={`${source}.newTo`} />
                <ArrayInput
                    label="Parent Categories"
                    source={`${source}.parentCategoryIds`}
                >
                    <SimpleFormIterator>
                        <NumberInput label="Category Number" source="" />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput label="Positions" source={`${source}.positions`}>
                    <SimpleFormIterator>
                        <TextInput label="Position" source="" />
                    </SimpleFormIterator>
                </ArrayInput>
                <BooleanInput label="Pre-Order" source={`${source}.preOrder`} />
                <TextInput
                    label="Personal Volume (PV)"
                    source={`${source}.PV`}
                    validate={validateStringPricing(
                        location,
                        true,
                        volumeErrorMsg
                    )}
                />
                <TextInput
                    label="Qualifying Volume (QV)"
                    source={`${source}.QV`}
                    validate={validateStringPricing(
                        location,
                        true,
                        volumeErrorMsg
                    )}
                />
                <TextInput
                    label="Commissionable Volume (CV)"
                    source={`${source}.CV`}
                    validate={validateStringPricing(
                        location,
                        true,
                        volumeErrorMsg
                    )}
                />
                <NumberInput
                    label="Host Value"
                    source={`${source}.hostValue`}
                />
                <NumberInput
                    label="Wholesale Price"
                    source={`${source}.wholesalePrice`}
                    validate={validateNumberPricing(location, false)}
                />
                <TextInput
                    label="Price"
                    source={`${source}.price`}
                    validate={validateStringPricing(location, true)}
                />
                <TextField
                    label="Price in cents (read-only)"
                    source={`${source}.priceInCents`}
                />
                <TextInput
                    label="Tax Class"
                    source={`${source}.taxClass`}
                    defaultValue="2"
                />
                <SelectInput
                    label="Size"
                    source={`${source}.size`}
                    choices={Sizes}
                />
                <TextInput
                    label="Product Volume"
                    source={`${source}.productVolume`}
                />
                <LongTextInput
                    label="Simple Description"
                    source={`${source}.simpleDescription`}
                />
                <TextInput
                    label="SKU"
                    source={`${source}.sku`}
                    validate={validateSKU(location)}
                />
                <NumberInput
                    label="Product ID"
                    validate={
                        location.pathname.includes('create') && validateProdId()
                    }
                    source={`${source}.productId`}
                    disabled={!location.pathname.includes('create')}
                />
                <NumberInput
                    label="UPC"
                    source={`${source}.upc`}
                    defaultValue={0}
                />
                <TextInput
                    label="Special Price"
                    source={`${source}.specialPrice`}
                    validate={validateStringPricing(location, false)}
                />
                <SelectInput
                    label="Status"
                    source={`${source}.status`}
                    isRequired={true}
                    defaultValue={1}
                    choices={Statuses}
                />
                <BooleanInput
                    label="Stock Notify"
                    source={`${source}.stockNotify`}
                />
                <ArrayInput label="Up Sell IDs" source={`${source}.upSellIds`}>
                    <SimpleFormIterator>
                        <NumberInput label="ID" source="" />
                    </SimpleFormIterator>
                </ArrayInput>
                <TextInput
                    label="Video Placeholder"
                    source={`${source}.video.videoLink`}
                />
                <LongTextInput label="Best For" source={`${source}.bestFor`} />
                <LongTextInput
                    label="Product Variety"
                    source={`${source}.productVariety`}
                />
                <ArrayInput
                    label="Certifications"
                    source={`${source}.certifications`}
                >
                    <SimpleFormIterator>
                        <TextInput label="Certification Name" />
                    </SimpleFormIterator>
                </ArrayInput>
                <AutoShipSettingsComponent
                    autoshipEligible={`${source}.autoshipEligible`}
                    autoshipFrequency={`${source}.autoshipFrequency`}
                    autoshipPeriod={`${source}.autoshipPeriod`}
                />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default withStyles(styles)(VariantEdit);
// export default VariantEdit;
