import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { IngredientActionForm } from './IngredientMissingAction';
import MissingIngredientList from './MissingIngredientList';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';



const styles = theme => ({
  root: {
    width: '90%',
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

function getSteps() {
  return ['Enter Ingredients', 'Add Ingredients to Firebase'];
}

class IngredientStepper extends Component {
  render() {
    const { classes, activeStep } = this.props;
    const steps = getSteps();
    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {this.props.missingIngredients.length >= 1 ? (
            <div>
             <MissingIngredientList 
                missingIngredients={this.props.missingIngredients} 
                handleClose={this.props.handleClose}
                basePath={this.props.basePath}
                associatedProductId={this.props.associatedProductId}
                associatedProductSku={this.props.associatedProductSku}
              /> 
            </div>
          ) :  this.props.activeStep == 1 && this.props.missingIngredients < 1 ? 
          (
            <div>
              <Typography>No Ingredients to Upload</Typography>
              <Button onClick={this.props.handleClose} color="primary">
                Cancel
            </Button>
            </div>
          )
          :
          (
            <div>
              <IngredientActionForm 
                handleIngredients={this.props.handleIngredients}
                handleClose={this.props.handleClose}
                checkForMissingIngredients={this.props.checkForMissingIngredients}
                ingredients={this.props.ingredients} 
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

IngredientStepper.propTypes = {
  classes: PropTypes.object,
  ingredients: PropTypes.array,
  checkForMissingIngredients: PropTypes.func,
  handleClose: PropTypes.func,
  handleIngredients: PropTypes.func, 
  missingIngredients: PropTypes.array,
  activeStep: PropTypes.number,
  submitToFirebase: PropTypes.func
};

export default withStyles(styles)(IngredientStepper);
