import React from 'react';
import { Login } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import firebaseAuthInstance from '../config/firebaseAuth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

const styles = {
    button: {
        width: '100%',
        marginTop: '1em'
    },
    input: {
        margin: '0 0 1em 0'
    },
    form: {
        marginBottom: '1em'
    }
};

const cardStyles = {
    main: {
        background:
            'url(https://source.unsplash.com/daily?ocean) no-repeat center center fixed',
        backgroundSize: 'cover'
    },
    card: {
        minWidth: 300,
        marginTop: '6em'
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        background: 'url(https://www.beautycounter.com/images/logo-white.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        margin: '10%',
        marginBottom: '15%',
        width: '80%',
        height: '100%'
    },
    icon: { top: '40px' }
};

const formStyles = {
    form: Object.assign(
        {
            padding: '0 1em 0em 1em'
        },
        styles.form
    ),
    input: Object.assign({}, styles.input),
    button: Object.assign(
        {
            background: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
            boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .30)',
            borderRadius: 3,
            border: 0,
            color: 'white',
            height: 48,
            opacity: 0.75
        },
        styles.button
    )
};

class CustomLoginPage extends React.Component {
    uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: '/#',
        signInOptions: [
            {
                provider:
                    firebaseAuthInstance.firebase_.auth.EmailAuthProvider
                        .PROVIDER_ID,
                recaptchaParameters: {
                    type: 'image',
                    size: 'normal',
                    badge: 'bottomleft'
                }
            }
        ],
        tosUrl: 'https://www.beautycounter.com/terms-of-use',
        privacyPolicyUrl: 'https://www.beautycounter.com/privacy-policy'
    };

    render() {
        return (
            <Login
                loginForm={
                    <div style={styles.form}>
                        <div style={formStyles.form}>
                            <StyledFirebaseAuth
                                style={styles.button}
                                uiConfig={this.uiConfig}
                                firebaseAuth={firebaseAuthInstance.auth()}
                            />
                        </div>
                    </div>
                }
                {...this.props}
            />
        );
    }
}

export default withStyles(cardStyles)(CustomLoginPage);
