import { getFirestoreCollection } from "../../validation/Ingredient";

const getFullIngredientsObject = (ingredients) => {
    const ingredientsDeepCopy = Object.assign({}, ingredients);
    const { fullIngredients } = ingredientsDeepCopy;
    return fullIngredients;
}

const findMissingIngredientsInFirestore = (productIngredients, firestoreIngredients) => {
    const regex = /(\.)|(Active Ingredients: )|(Ingredients:)|(\*)|(†)|(‡)|(May Contain\/Peut contenir)/g;
    const Ingredients = productIngredients.map(ingredient => ingredient.replace(regex,"").trim());
    const missingIngredients = [];
    Ingredients.map((ingredient) => {
        if(!firestoreIngredients.includes(ingredient)){
            missingIngredients.push(ingredient);
        }
    });
    return missingIngredients;
}

export const IngredientParse = async (ingredients, resource) => {
    const fireStoreCollection = await getFirestoreCollection(resource);
    const missingIngredients = await findMissingIngredientsInFirestore(ingredients.split(','), fireStoreCollection);
    return missingIngredients; 
    
}