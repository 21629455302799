import grey from '@material-ui/core/colors/grey';

export const palette = {
    primary: {
        ...grey,
        // light: '',
        main: '#000000',
        // dark: '',
        contrastText: '#ffffff',
    },
    secondary: {
        // ...grey,
        // light: '',
        main: '#ffffff',
        // dark: '',
        contrastText: '#000000',
    },
    background: {
        // paper,
        // default,
        primary: '#F7F9FC',
        secondary: '#F3F8F7',
    },
    status: {
        primary: '#007198',
    },
    random: { foo: 'foo' },
    contrastThreshold: 3,
};
