/* eslint-disable no-param-reassign */

import { flattenArrayProperties } from './util';
import { randomId } from './util';

export const getIngredientList = async (firestore, resource, params) => {
    var collection = firestore.collection(resource);
    var query = collection;

    if (params.filter.ingredientId) {
        query = query.where(
            'ingredientId',
            '==',
            parseInt(params.filter.ingredientId, 10)
        );
    }

    if (params.sort.field !== 'id') {
        query = query.orderBy(
            params.sort.field,
            params.sort.order.toString().toLowerCase()
        );
    }

    var allDocs = await query.get().then(snapshot => snapshot.docs);
    var curDocs = [];

    if (allDocs.length > 0) {
        var startIndex;
        var endIndex;

        if (params.filter.title) {
            startIndex = 0;
            endIndex = allDocs.length;
        } else {
            startIndex =
                (params.pagination.page - 1) * params.pagination.perPage;
            endIndex =
                startIndex + params.pagination.perPage > allDocs.length
                    ? allDocs.length
                    : startIndex + params.pagination.perPage;
        }

        for (var i = startIndex; i < endIndex; i++) {
            const data = allDocs[i].data();
            if (params.filter.title) {
                const searchTitle = params.filter.title
                    .toString()
                    .toLowerCase();
                if (
                    data.title &&
                    data.title.toLowerCase().includes(searchTitle)
                ) {
                    curDocs.push({ ...data, id: allDocs[i].id });
                }
            } else {
                curDocs.push({ ...data, id: allDocs[i].id });
            }
        }
    }

    var titleSearchTotal = curDocs.length;

    //If title search has more results than page amount
    //Only display page amount
    if (params.filter.title && curDocs.length > params.pagination.perPage) {
        const first = (params.pagination.page - 1) * params.pagination.perPage;
        curDocs = curDocs.slice(first, first + params.pagination.perPage);
    }

    return {
        data: curDocs,
        total: params.filter.title ? titleSearchTotal : allDocs.length
    };
};

/**
 * Summary: Gets ingredient information
 * @param {Object} firestore - Firestore SDK
 * @param {Object} resource - Collection resource
 * @param {Object} params - ingredient parameters
 */

export const getIngredient = async (firestore, resource, params) => {
    let ingredient;
    let productIds = [];
    let properties = [];
    const query = await firestore
        .collection(resource)
        .doc(params.id)
        .get()
        .then(data => {
            let doc = data.data();
            if (doc.associatedProductIds) {
                doc.associatedProductIds.map(productId => {
                    productIds.push({ productId });
                });
            }
            if (doc.properties) {
                doc.properties
                    .filter(property => property)
                    .map(property => {
                        properties.push({
                            description: property.description || '',
                            enabled: property.enabled,
                            propertyId: property.propertyId,
                            title: property.title
                        });
                    });
            }
            ingredient = doc;
        });
    return {
        data: {
            ...ingredient,
            id: params.id,
            properties,
            associatedProductIds: productIds
        }
    };
};

export const createIngredient = async (firestore, resource, params) => {
    const createIngredientData = params.data;

    createIngredientData.ingredientId = randomId();

    if (createIngredientData.properties) {
        createIngredientData.properties.forEach((prop, index) => {
            prop.propertyId = randomId();
            prop.description = prop.description || '';
        });
    }

    await firestore
        .collection(resource)
        .add(createIngredientData)
        .then(() => {
            console.info('ingredient created successfully');
        })
        .catch(error => {
            console.error(error);
        });

    return {
        data: {
            ...createIngredientData,
            id: params.id
        }
    };
};

/**
 * Updates ingredient
 * @param {Object} firestore - Firestore SDK
 * @param {Object} resource - Collection resource
 * @param {Object} params - ingredient parameters
 *
 */

export const updateIngredient = async (firestore, resource, params) => {
    const ingredient = params.data;

    for (let property in ingredient) {
        if (property == 'associatedProductIds') {
            let flatProperties = flattenArrayProperties(ingredient[property]);
            ingredient[property] = flatProperties;
        }
    }

    if (ingredient.properties) {
        ingredient.properties.forEach(property => {
            property.description = property.description || '';
            property.propertyId = property.propertyId || randomId();
        });
    }

    ingredient.updatedAt = new Date().toDateString();

    await firestore
        .collection(resource)
        .doc(params.id)
        .update(ingredient)
        .then(() => {
            console.info('document successfully updated');
        })
        .catch(error => {
            console.error(error);
        });

    return {
        data: { id: params.id, ...ingredient }
    };
};

export const deleteIngredient = async (firestore, resource, params) => {
    const ingredient = params.data;

    params.previousData.enabled = false;

    for (let property in ingredient) {
        if (property == 'associatedProductIds') {
            let flatProperties = flattenArrayProperties(ingredient[property]);
            ingredient[property] = flatProperties;
        }
    }
    if (ingredient.updatedAt) {
        ingredient.updatedAt = new Date().toISOString();
    }
    await firestore
        .collection(resource)
        .doc(params.id)
        .update(ingredient)
        .then(() => {
            console.log('document disabled');
        })
        .catch(error => {
            console.error(error);
        });

    return { data: params };
};
