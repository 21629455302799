import { required } from 'react-admin';
import { catalogsConfig } from '../config';
import dollarsToCents from 'dollars-to-cents';

let firestore;
var skus = [];
var prodIds = [];
var handles = [];

export const validateType = [required('Type is required.')];
export const validateStatus = [required('Status is required.')];
export const validateVisibility = [required('Visibility is required.')];

const getData = collection => {
    const collectionRef = firestore.collection(collection);

    collectionRef.get().then(snapshot => {
        snapshot.forEach(doc => {
            const data = doc.data();

            if (!skus.includes(data.sku)) {
                skus.push(data.sku);
            }

            if (!prodIds.includes(data.productId)) {
                prodIds.push(data.productId);
            }

            if (!handles.includes(data.handle)) {
                handles.push(data.handle);
            }
        });
    });
};

export const initializeProductData = fs => {
    firestore = fs;

    getData(catalogsConfig.enUS.catalog);
    getData(catalogsConfig.enCA.catalog);
    getData(catalogsConfig.frCA.catalog);
};

export const volumeErrorMsg =
    'Please enter a number value or 0 for not applicable.';

export const noDuplicates = (
    list,
    message = "Can't have duplicates."
) => value => (value && list.includes(value) ? message : undefined);
export const checkLowPricing = (
    message = 'Price must be equal to $1 or above'
) => value => (value && dollarsToCents(value) < 100 ? message : undefined);
export const checkValidStringPricing = (
    message = 'Only numerics please, no space or $ sign and just one decimal point.'
) => value =>
    value &&
    (typeof value != 'string' ||
        value.indexOf(' ') >= 0 ||
        isNaN(value) ||
        isNaN(parseFloat(value)))
        ? message
        : undefined;
export const checkValidNumberPricing = (
    message = 'Please only use numerics.  Special characters or $ sign not allowed.'
) => value =>
    value && (isNaN(value) || isNaN(parseFloat(value))) ? message : undefined;

export const validateProdId = () => {
    const validations = [];
    validations.push(
        noDuplicates(prodIds, "Can't have a duplicate of a Product ID.")
    );

    return validations;
};

export const validateSKU = location => {
    const validations = [required('SKU is required.')];
    if (location && location.pathname.includes('create')) {
        validations.push(
            noDuplicates(skus, "Can't have a duplicate of an SKU.")
        );
    }

    return validations;
};

export const validateStringPricing = (
    location,
    isRequired = false,
    message = 'Price is required.'
) => {
    const validations = isRequired === true ? [required(message)] : [];
    if (
        location &&
        (location.pathname.includes('create') ||
            !location.pathname.includes('create'))
    ) {
        validations.push(checkValidStringPricing());
    }

    return validations;
};

export const validateNumberPricing = (location, isRequired = false) => {
    const validations =
        isRequired === true ? [required('Price is required.')] : [];
    if (
        location &&
        (location.pathname.includes('create') ||
            !location.pathname.includes('create'))
    ) {
        validations.push(checkValidNumberPricing());
    }

    return validations;
};

const noProductSkuDuplicates = (skus, newSKU) => {
    let isSKUValid = true;
    if (skus.includes(newSKU) === true) {
        isSKUValid = false;
        return isSKUValid;
    }
    return isSKUValid;
};

export const validateSKUForBulkUpload = newSKU => {
    return noProductSkuDuplicates(skus, newSKU);
};

export const validateHandle = location => {
    const validations = [required('Handle is required.')];
    if (location.pathname.includes('create')) {
        validations.push(
            noDuplicates(handles, "Can't have a duplicate of handle.")
        );
    }

    return validations;
};
