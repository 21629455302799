import { withTheme } from '@material-ui/core/styles';

export const table = {
    MuiTable: {
        root: {
            border: '2px solid black',
        },
    },
    MuiTableCell: {
        root: {
            borderBottom: '2px solid black',
            '&$paddingNone': {
                padding: '0 3rem',
            },
        },
        head: {
            color: 'white',
            fontWeight: 900,
        },
    },
    MuiTableHead: {
        root: {
            background: 'black',
            textTransform: 'uppercase',
        },
    },
    MuiTableSortLabel: {
        root: {
            color: 'white',
            '&:hover': {
                color: 'grey',
            },
            '&:focus': {
                color: 'white',
            },
        },
        active: {
            color: 'white',
        },
    },
    MuiTableRow: {
        root: {
            height: '5rem',
        },
    },
};
