export const UserTypes = [
    { id: 1, name: 'Distributor' },
    { id: 2, name: 'Preferred Customer' },
    { id: 3, name: 'Retail Customer' },
    { id: 4, name: 'Employee' },
    { id: 5, name: 'Prospect' },
    { id: 7, name: 'Guest' }
];

export const LanguageVariants = [
    { id: 'en-us', name: 'US' },
    { id: 'en-ca', name: 'CA EN' },
    { id: 'fr-ca', name: 'CA FR' },
];

export const Flags = [
    { id: 'enrollment', name: 'Enrollment' },
    { id: 'web', name: 'Web' },
    { id: 'mobile', name: 'Mobile' },
    { id: 'retail', name: 'Retail' },
    { id: 'counterbase', name: 'Counterbase'}
];

export const Statuses = [{ id: '1', name: 'Enabled' }, { id: '0', name: 'Disabled' }];

export const Sizes = [
    { id: 'travel', name: 'Travel' },
    { id: 'sample', name: 'Sample' },
    { id: 'custom', name: 'Custom' },
    { id: 'xs', name: 'XS' },
    { id: 's', name: 'S' },
    { id: 'm', name: 'M' },
    { id: 'l', name: 'L' },
    { id: 'xl', name: 'XL' },
    { id: 'xxl', name: 'XXL' }
];

export const Visibility = [
    { id: '1', name: 'Not Visible Individually' },
    { id: '2', name: 'Catalog' },
    { id: '3', name: 'Search' },
    { id: '4', name: 'Catalog, Search' },
];

export const ProductTypes = [
    { id: 'simple', name: 'Simple' },
    { id: 'sample', name: 'Sample' },
    { id: 'configurable', name: 'Configurable' },
    { id: 'bundle', name: 'Bundle' },
];

export const FinalSaleTypes = [
    { id: 0, name: 'No Final Sale' },
    { id: 1, name: 'Final Sale' },
    { id: 2, name: 'Final Sale With Message' }
]

export const InStock = [{ id: '1', name: 'Yes' }, { id: '0', name: 'No' }];

export const isShippableFalseExceptionsHotfix = [
    '9000',
    '9916',
    '15999',
    '16000',
    '16001',
    '16002',
    '16003',
    '16004',
    '16005',
    '16006',
    '700000049',
    '700000050',
    '700000051',
    '700000033',
    '700000032',
    '700000031',
    '700000030',
    '700000029',
];

export const locales = ['en-us', 'en-ca', 'fr-ca'];

export const VariantStyles = [
    { id: 0, name: 'Hex Code with Label Variant' },
    { id: 1, name: 'Image with Label Variant' },
]