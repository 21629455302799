import React from 'react';
import { List, Datagrid, TextField, EditButton, Edit } from 'react-admin';

import RollbackForm from '../forms/RollbackForm';

export const BackupsList = props => (
    <List {...props}>
        <Datagrid>
            <TextField label="Status" source="status" />
            <TextField label="Rolled back date" source="rolledbackDate" />
            <TextField label="Label" source="label" />
            <TextField label="Published date" source="backupDate" />
            <EditButton label="Actions" />
        </Datagrid>
    </List>
);

export const BackupsEdit = props => (
    <Edit title="Backup Actions" {...props}>
        <RollbackForm {...props} />
    </Edit>
);
