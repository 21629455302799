import React from 'react';
import {
    ArrayInput,
    SimpleFormIterator,
    ImageField,
    BooleanInput,
    ImageInput,
    TextInput
} from 'react-admin';
import NumberInput from './ReactAdminCustom/NumberInput';

export const ProductImage = props => {
    return (
        <div>
            <ImageInput label="Image" source="image.imageUrl">
                <ImageField source="" />
            </ImageInput>
            <div>
                {props.record.image && props.record.image.imageUrl ? (
                    <img
                        src={props.record.image.imageUrl}
                        style={{ width: 250, height: 250 }}
                    />
                ) : null}
            </div>
            <TextInput label="Image Alt Text" source="image.altText" />
            <ImageInput
                label="Bundled Sku Interrupt Image"
                source="image.bundledSkuInterruptImageUrl"
            >
                <ImageField source="" />
            </ImageInput>
            <TextInput
                label="Interrupt Image Alt Text"
                source="image.bundledSkuInterruptImageAltText"
            />
            <div>
                {props.record.image &&
                props.record.image.bundledSkuInterruptImageUrl ? (
                    <img
                        src={props.record.image.bundledSkuInterruptImageUrl}
                        style={{ width: 'auto', height: 250 }}
                    />
                ) : null}
            </div>
        </div>
    );
};
export const ProductVariantImage = props => {
    const { source } = props;

    return (
        <React.Fragment>
            <ImageInput label="Image" source={source + '.image.imageUrl'}>
                <ImageField source="" />
            </ImageInput>

            <TextInput
                label="Image Alt Text"
                source={source + '.image.altText'}
            />
            {props.record.image && props.record.image.imageUrl ? (
                <img
                    src={props.record.image.imageUrl}
                    style={{ width: 250, height: 250 }}
                />
            ) : null}
            <ImageInput
                label="Bundled Sku Interrupt Image"
                source={source + '.image.bundledSkuInterruptImageUrl'}
            >
                <ImageField source="" />
            </ImageInput>
            <TextInput
                label="Interrupt Image Alt Text"
                source={source + '.image.bundledSkuInterruptImageAltText'}
            />
            <div>
                {props.record.image &&
                props.record.image.bundledSkuInterruptImageUrl ? (
                    <img
                        src={props.record.image.bundledSkuInterruptImageUrl}
                        style={{ width: 'auto', height: 250 }}
                    />
                ) : null}
            </div>
        </React.Fragment>
    );
};

export const ProductGallery = props => {
    return (
        <ArrayInput
            label="Gallery"
            source="image.gallery"
            style={{ width: '100%' }}
        >
            <SimpleFormIterator>
                <ImageInput label="Gallery Image" source="url">
                    <ImageField label="URL" source="url" />
                </ImageInput>
                <TextInput label="Image Alt Text" source="altText" />
                <BooleanInput label="Disabled" source="disabled" />
                <NumberInput label="Sort Order" source="sortOrder" />
            </SimpleFormIterator>
        </ArrayInput>
    );
};
