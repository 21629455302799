import { createMuiTheme } from '@material-ui/core/styles';
import { table } from './overwrites/table';
import { palette } from './colors/palette';

const breakpoints = {};

const typography = {
    fontFamily: ['Mark', 'Helvetica Neue', 'Helvetica', 'sans-serif'].join(','),

    headline: {
        textTransform: 'uppercase',
        fontWeight: 900,
    },
};

const overrides = {
    table,
    MuiCard: {
        root: {
            padding: '2rem 4rem',
        },
    },
    MuiDrawer: {
        docked: {
            background: '#F4F7F9',
        },
    },
};

const theme = createMuiTheme({
    breakpoints,
    palette,
    typography,
    overrides,
});

export default theme;
