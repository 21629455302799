import React, { useState } from 'react';
import {
    TextInput,
    LongTextInput,
    ArrayInput,
    SimpleFormIterator,
    ImageInput,
    ImageField,
    SelectInput,
    FormDataConsumer,
    REDUX_FORM_NAME
} from 'react-admin';
import { change } from 'redux-form';
import NumberInput from './ReactAdminCustom/NumberInput';
import { matchSkuToProductID } from '../dataProvider/Product';

const BundleEdit = props => {
    const updateEvent = async event => {
        const newValue = await matchSkuToProductID(
            props.resource,
            event.currentTarget.value
        );
        return newValue;
    };

    return (
        <ArrayInput label="Bundled Options" source="bundledOptions">
            <SimpleFormIterator>
                <TextInput label="Default Title" source="defaultTitle" />
                <TextInput label="Store View Title" source="storeViewTitle" />
                <NumberInput label="Option ID" source="optionId" />
                <ArrayInput label="Details" source="bundledOptionValues">
                    <SimpleFormIterator>
                        <TextInput label="Name" source="name" />
                        <NumberInput
                            label="Selection ID"
                            source="selectionId"
                        />

                        <FormDataConsumer>
                            {({
                                getSource,
                                scopedFormData,
                                dispatch,
                                ...rest
                            }) => (
                                <>
                                    <TextInput
                                        source={getSource('sku')}
                                        onChange={async value => {
                                            const newVal = await updateEvent(
                                                value
                                            );
                                            dispatch(
                                                change(
                                                    REDUX_FORM_NAME,
                                                    getSource('productId'),
                                                    newVal
                                                )
                                            );
                                        }}
                                        record={scopedFormData}
                                        {...rest}
                                        label="SKU"
                                    />

                                    <NumberInput
                                        source={getSource('productId')}
                                        disabled
                                        record={scopedFormData}
                                        {...rest}
                                        label="Product ID 🔗 SKU)"
                                    />
                                </>
                            )}
                        </FormDataConsumer>

                        <LongTextInput
                            label="Color Description"
                            source="fmfColorDescription"
                        />
                        <SelectInput
                            label="In Stock"
                            source="inStock"
                            choices={[
                                { id: '1', name: 'Yes' },
                                { id: '0', name: 'No' }
                            ]}
                        />
                        <ImageInput label="FMF Image" source="fmfImage">
                            <ImageField source="fmfImage" />
                        </ImageInput>
                        <ImageField src="fmfImage" />
                        <TextInput
                            label="FMF Image Alt Text"
                            source="fmfImageAltText"
                        />
                        <ImageInput
                            label="FMF Image Review"
                            source="fmfImageReview"
                        >
                            <ImageField source="fmfImageReview" />
                        </ImageInput>
                        <TextInput
                            label="FMF Image Review Alt Text"
                            source="fmfImageReviewAltText"
                        />
                        <ImageInput
                            label="Swatch Graphic"
                            source="swatchGraphic"
                        >
                            <ImageField source="swatchGraphic" />
                        </ImageInput>
                        <TextInput
                            label="Swatch Graphic Alt Text"
                            source="swatchGraphicAltText"
                        />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default BundleEdit;
