export const AllowedFirestoreFields = [
    'typeId',
    'sku',
    'parentSku',
    'productId',
    'categories',
    'title',
    'draft',
    'status',
    'inStock',
    'productVolume',
    'flags',
    'isShippable',
    'maxQuantity',
    'visibility',
    'price',
    'specialPrice',
    'PV',
    'QV',
    'CV',
    'SV',
    'handle',
    'howToUse',
    'description',
    'simpleDescription',
    'metaDescription',
    'fullIngredients',
    'newFrom',
    'newTo'
];

export const FirestoreNumberFields = ['maxQuantity'];

export const convertToFirestoreDataType = [
    { typeId: value => value.toString() },
    { sku: value => value.toString() },
    { parentSku: value => value?.toString() || null },
    {
        productId: value =>
            value === '' || value === undefined ? null : Number(value)
    },
    { categories: value => value.split(',').map(Number) },
    { title: value => value.toString() },
    {
        draft: value => value === 'true' || value === 'TRUE' || value === 'True'
    },
    { status: value => value.toString() },
    {
        inStock: value =>
            value === 'true' || value === 'TRUE' || value === 'True'
    },
    { productVolume: value => value.toString() },
    { flags: value => value.split(',').map(String) },
    {
        isShippable: value =>
            value === 'true' || value === 'TRUE' || value === 'True'
    },
    {
        maxQuantity: value =>
            value === '' || value === undefined ? 10 : parseInt(value, 10)
    },
    { visibility: value => value.toString() },
    { price: value => value.toString() },
    { specialPrice: value => value.toString() },
    { PV: value => value.toString() },
    { QV: value => value.toString() },
    { CV: value => value.toString() },
    { SV: value => value.toString() },
    { handle: value => value.toString() }, // required
    { howToUse: value => value.toString() },
    { description: value => value.toString() },
    { simpleDescription: value => value.toString() },
    { metaDescription: value => value.toString() },
    { fullIngredients: value => value.toString() },
    { newFrom: value => value.toString() },
    { newTo: value => value.toString() }
];
