import { initializeApp } from 'firebase';
import { environments } from '.';

// weird issue - had to hard code the variable below because this variable never
// gets passed through.
// const environment = environments[process.env.REACT_AUTH_FIREBASE];
// console.log(process.env);
// console.log(environment);

const defaultInstance = initializeApp(
    environments['beautycounter-prod'].firebaseConfig
);

const fb = initializeApp(
    environments['beautycounter-prod'].firebaseConfig,
    'authInstance'
);

export { defaultInstance };
export default fb;
