import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import csv from 'csvtojson';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { AllowedFirestoreFields } from '../constants/firestoreFields';
import {
    matchFirestoreDataTypes,
    sanitizeDataForFirestore,
    uploadVariationToFirestore
} from '../utils/bulkUpload';
import PublishCatalog from '../components/PublishCatalog';
import { getUser } from '../auth/AuthConfig';
import firebaseAuthInstance from '../config/firebaseAuth';
import { catalogsConfig } from '../config';

const styles = {
    root: {
        padding: 40
    },
    button: {
        padding: 20
    }
};

const DropZoneStyle = {
    outline: 'solid',
    width: '30%',
    height: '30%',
    padding: 40,
    marginBottom: 40
};

class DashboardPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            data: [],
            isLoading: true,
            selectedCollectionValue: [],
            isHakuAdmin: false
        };

        this.collectionChoiceHandler = this.collectionChoiceHandler.bind(this);
        this.submitToFirestore = this.submitToFirestore.bind(this);
    }

    componentDidMount() {
        this.isAdmin();
    }

    isAdmin = async () => {
        const { uid } = await getUser();

        if (!uid) return;
        const doc = await firebaseAuthInstance
            .firestore()
            .collection('cs-users')
            .doc(uid)
            .get();

        const csUserData = doc.data();

        if (csUserData && csUserData.roles) {
            const { hakuAdmin } = csUserData.roles;
            this.setState({
                isHakuAdmin: hakuAdmin
            });
        }
    };

    onDrop = file => {
        const reader = new FileReader();
        const data = [];
        const errorsFieldTypes = [];

        reader.onload = () => {
            const fileAsBinaryString = reader.result;
            csv({ noheader: true, output: 'json' })
                .fromString(fileAsBinaryString)
                .then(csvRows => {
                    csvRows.forEach((aCsvRow, i) => {
                        if (i !== 0) {
                            const builtObject = {};
                            let keyToAddInBuiltObject;
                            Object.keys(aCsvRow).forEach(aKey => {
                                const valueToAddInBuiltObject = aCsvRow[aKey];
                                if (csvRows[0][aKey].includes('¿')) {
                                    keyToAddInBuiltObject = csvRows[0][
                                        aKey
                                    ].split('¿')[1];
                                } else {
                                    keyToAddInBuiltObject = csvRows[0][aKey];
                                }
                                if (
                                    !AllowedFirestoreFields.includes(
                                        keyToAddInBuiltObject
                                    )
                                ) {
                                    errorsFieldTypes.push(
                                        keyToAddInBuiltObject
                                    );
                                }
                                builtObject[
                                    keyToAddInBuiltObject
                                ] = valueToAddInBuiltObject;
                            });
                            data.push(builtObject);
                        }
                    });
                    matchFirestoreDataTypes(data);
                    const firestoreData = sanitizeDataForFirestore(data);
                    if (
                        errorsFieldTypes.length > 0 ||
                        firestoreData === false
                    ) {
                        console.error({ errorsFieldTypes, firestoreData });
                        console.error(
                            `There was a problem with the upload. Please ensure that you have the necessary fields`
                        );
                    } else {
                        this.setState({
                            data: firestoreData,
                            isLoading: false
                        });
                    }
                });
        };
        reader.readAsBinaryString(file[0]);
    };

    collectionChoiceHandler(event) {
        this.setState({ selectedCollectionValue: event.target.value });
    }

    async submitToFirestore() {
        const confirm = window.confirm(
            `Submit data to the ${this.state.selectedCollectionValue} collection ?`
        );
        if (confirm === true) {
            try {
                const isUploaded = await uploadVariationToFirestore(
                    this.state.data,
                    this.state.selectedCollectionValue
                );
                if (isUploaded) {
                    console.error(
                        `There were ${this.state.data.length} products uploaded to firestore`
                    );
                }
                this.setState({ isLoading: false, data: [] });
            } catch (error) {
                console.error(error);
            }
        } else {
            window.location.reload();
        }
    }

    render() {
        const { data, selectedCollectionValue } = this.state;
        const { classes } = this.props;

        const displayCollectionQuestion =
            data.length > 0 ? (
                <Typography>
                    {' '}
                    Upload to{' '}
                    {selectedCollectionValue.map(
                        collectionName => `${collectionName}, `
                    )}{' '}
                    ?{' '}
                </Typography>
            ) : null;
        const collectionChoice =
            this.state.data.length > 0 ? (
                <div>
                    <Select
                        multiple
                        value={this.state.selectedCollectionValue}
                        onChange={this.collectionChoiceHandler}
                    >
                        <MenuItem value={catalogsConfig.enUS.catalog}>
                            {catalogsConfig.enUS.label}
                        </MenuItem>
                        <MenuItem value={catalogsConfig.enCA.catalog}>
                            {catalogsConfig.enCA.label}
                        </MenuItem>
                        <MenuItem value={catalogsConfig.frCA.catalog}>
                            {catalogsConfig.frCA.label}
                        </MenuItem>
                    </Select>
                    <Button variant="outlined" onClick={this.submitToFirestore}>
                        Submit to Firestore
                    </Button>
                </div>
            ) : null;

        return (
            <React.Fragment>
                <div className={classes.root}>
                    <Typography component="h2">Product Bulk Upload</Typography>
                    <br />
                    <Dropzone
                        onDrop={this.onDrop}
                        accept=".csv"
                        multiple={false}
                        style={DropZoneStyle}
                    >
                        <Typography>Drop .csv File Here</Typography>
                    </Dropzone>
                    <br />
                    <Typography>
                        <a
                            style={{ textDecoration: 'none' }}
                            href="https://firebasestorage.googleapis.com/v0/b/haku-catalog.appspot.com/o/haku-bulk-csv-template%2Fhaku_bulk.csv?alt=media"
                            download
                        >
                            Download File Template
                        </a>
                    </Typography>
                    {displayCollectionQuestion}
                    <br />
                    {collectionChoice}
                    <Typography>
                        There are {data.length} products to upload
                    </Typography>
                    {data.map((item, i) => (
                        <div key={i}>
                            <Typography>
                                Type: {item.type} SKU: {item.sku} ProductId:{' '}
                                {item.productId}
                            </Typography>
                        </div>
                    ))}
                </div>

                {/* begin publish component */}
                {this.state.isHakuAdmin &&
                    !catalogsConfig.useTestCollections && <PublishCatalog />}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DashboardPage);
