import { environments } from '../config/index';
import { locales } from '../constants/returnTypes';
import { find } from 'lodash';

import firebaseAuthInstance from '../config/firebaseAuth';
import firestore from '../config/firestore';

const adminInstance = projectName => {
    const alreadyInitialized = firebaseAuthInstance.apps.filter(app =>
        app.name_.includes(projectName)
    );

    return !alreadyInitialized.length
        ? firebaseAuthInstance.initializeApp(
              environments[projectName].firebaseConfig,
              projectName
          )
        : alreadyInitialized[0];
};

const updateBackupRecord = async (backupId, email) => {
    try {
        await firestore
            .collection('haku-backups')
            .doc(backupId)
            .update({
                rolledbackDate: new Date().toLocaleString(),
                status: 'Rolled back',
                rolledbackBy: email
            });
    } catch (error) {
        console.info(error);
    }
};

export const rollback = async (backupId, email) => {
    const collections = [];

    locales.map(code =>
        collections.push(
            `products-${code}`,
            `categories-${code}`,
            `filters-${code}`
        )
    );

    try {
        // change to beautycounter-drafts before deploy
        const draftsDb = await adminInstance('haku-catalog').firestore();

        const promisedCatalogsBackup = collections.map(collection =>
            draftsDb
                .collection('haku-backups')
                .doc(backupId)
                .collection(collection)
                .get()
        );

        const promisedCatalogsBackupData = await Promise.all(
            promisedCatalogsBackup
        );

        return Promise.all(
            collections.map(async (collection, key) => {
                const rollbackToData = await draftsDb
                    .collection(collection)
                    .get();

                await Promise.all(
                    promisedCatalogsBackupData[key].docs.map(
                        async (doc, docKey) => {
                            await draftsDb
                                .collection(collection)
                                .doc(doc.id)
                                .set(doc.data());
                        }
                    )
                );

                await Promise.all(
                    rollbackToData.docs.map(async (doc, docKey) => {
                        const localDoc = find(
                            promisedCatalogsBackupData[key].docs,
                            {
                                id: doc.id
                            }
                        );

                        if (!localDoc) {
                            console.info('delete', collection, doc.id);
                            await draftsDb
                                .collection(collection)
                                .doc(doc.id)
                                .delete();
                        }
                    })
                );
            })
        ).then(() => {
            console.info('rollback completed');
            updateBackupRecord(backupId, email);
        });
    } catch (error) {
        console.info('rollback() error', error);
    }
};
