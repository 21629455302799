import firebaseAuthInstance from '../config/firebaseAuth';

let user;

export const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/#',
    signInOptions: [
        firebaseAuthInstance.firebase_.auth.EmailAuthProvider.PROVIDER_ID
    ],
    tosUrl: 'https://www.beautycounter.com/terms-of-use',
    privacyPolicyUrl: 'https://www.beautycounter.com/privacy-policy'
};

export const authConfig = {
    handleAuthStateChange: userFirebase => {
        if (userFirebase) {
            user = {
                displayName: userFirebase.displayName || userFirebase.email,
                email: userFirebase.email,
                emailVerified: userFirebase.emailVerified,
                photoUR: userFirebase.photoURL,
                isAnonymous: userFirebase.isAnonymous,
                uid: userFirebase.uid,
                providerData: userFirebase.providerData
            };
        } else {
            user = null;
            console.log('Firebase User Signed Out!');
        }
    },
    handleAuthStateChangeError: error => {
        console.error(error);
        return firebaseAuthInstance.auth().signOut();
    },
    handleAuthStateChangeComplete: () => {
        console.log('Auth state observer removed');
        return firebaseAuthInstance.auth().signOut();
    }
};

firebaseAuthInstance.auth().useDeviceLanguage();
firebaseAuthInstance
    .auth()
    .onAuthStateChanged(
        authConfig.handleAuthStateChange,
        authConfig.handleAuthStateChangeError,
        authConfig.handleAuthStateChangeComplete
    );

export const getUser = () => {
    if (user) {
        return user;
    }

    return new Promise(function(resolve, reject) {
        firebaseAuthInstance.auth().onAuthStateChanged(
            firebaseUser => {
                authConfig.handleAuthStateChange(firebaseUser);

                if (firebaseUser) {
                    resolve(firebaseUser);
                } else {
                    reject(Error('Login Failed!'));
                }
            },

            error => {
                authConfig.handleAuthStateChangeError(error);
                reject(Error('Auth Error!'));
            },

            () => {
                authConfig.handleAuthStateChangeComplete();
                resolve();
            }
        );
    });
};
