import React from 'react';
import { ArrayInput, SimpleFormIterator, TextInput } from 'react-admin';
import NumberInput from './ReactAdminCustom/NumberInput';

/**
 * @param {Object} props - Properties from firestore getOne request
 */

export const CategoriesInputInformation = props => {
    return (
        <ArrayInput label="Categories" source="categories">
            <SimpleFormIterator>
                <NumberInput label="Category Number" source="" />
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export const ParentCategoriesInputInformation = props => {
    return (
        <ArrayInput label="Parent Categories" source="parentCategoryIds">
            <SimpleFormIterator>
                <NumberInput label="Category Number" source="" />
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export const CategoryPositionInformation = props => {
    return (
        <ArrayInput label="Positions" source="positions">
            <SimpleFormIterator>
                <TextInput label="Category" source="category" />
                <NumberInput label="Position" source="positioncategory" />
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export const CategoryUpSellIdInformation = props => {
    return (
        <ArrayInput label="Up Sell IDs" source="upSellIds">
            <SimpleFormIterator>
                <NumberInput label="ID" source="" />
            </SimpleFormIterator>
        </ArrayInput>
    );
};
