import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { uploadMissingIngredientsToFirestore } from '../../utils/missingIngredientUpload';


const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

class MissingIngredientList extends React.Component {
  state = {
    checked: [],
  };

  handleToggle = value => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };

  submitToFirebase = selectedIngredients => async () => {
    const resource = this.props.basePath.split('/')[1];
    await uploadMissingIngredientsToFirestore(selectedIngredients, resource, this.props.associatedProductId, this.props.associatedProductSku);
    await this.props.handleClose();
  }

  render() {
    const { classes } = this.props;
    const { missingIngredients } = this.props;
    
    return (
      <List className={classes.root}>
        {missingIngredients.map(value => (
          <ListItem key={value} role={undefined} dense button onClick={this.handleToggle(value)}>
            <Checkbox
              color="primary"
              checked={this.state.checked.indexOf(value) !== -1}
              tabIndex={-1}
              disableRipple
            />
            <ListItemText primary={`${value}`} />
          </ListItem>
        ))}
         <Button onClick={this.props.handleClose} color="primary">
                Cancel
        </Button>
        <Button onClick={this.submitToFirebase(this.state.checked)} color="primary">
                Submit to Firestore
        </Button>
      </List>
    );
  }
}

MissingIngredientList.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func,
  missingIngredients: PropTypes.array, 
};

export default withStyles(styles)(MissingIngredientList);