/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable import/order */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import {
    FirestoreNumberFields,
    convertToFirestoreDataType
} from '../constants/firestoreFields';
import { validateSKUForBulkUpload } from '../validation/Product';
import firestore from '../config/firestore';

export const matchFirestoreDataTypes = data => {
    const items = [];
    data.map(item => {
        for (let key in item) {
            item[key] = convertDataType(key, item[key]);
        }
        items.push(item);
    });
    return items;
};

const convertDataType = (key, item) => {
    let convertedKey;
    convertToFirestoreDataType.map(func => {
        for (const functionKey in func) {
            if (functionKey === key) {
                convertedKey = func[key](item);
            }
        }
    });
    return convertedKey;
};

export const sanitizeDataForFirestore = products => {
    const configurables = [];
    const variants = [];
    const simples = [];

    const isProductsValid = [
        checkForSKUs(products),
        checkForProductId(products),
        checkForHandles(products),
        checkValueTypes(products),
        injectProductSkus(products)
    ];

    console.error(isProductsValid);

    if (isProductsValid.includes(false)) {
        return false;
    }

    products.reduce((acc, curr) => {
        if (curr.typeId === 'configurable') {
            curr.variants = [];
            curr.variants.push(
                ...products.filter(product => product.parentSku === curr.sku)
            );
            configurables.push(curr);
        }

        if (curr.typeId === 'simple') {
            simples.push(curr);
        }

        if (curr.typeId === 'variant') {
            variants.push(curr);
        }

        acc = [...configurables, ...variants, ...simples];
        return acc;
    }, []);

    // leave this here to debug on the client
    console.table([...configurables, ...variants, ...simples]);

    return [...configurables, ...simples];
};

/**
 * Overview: Check to see if a productId exists. Product Id is required,
 * entered manually, and comes from Magento.
 * @param {Array} data
 */
const checkForProductId = data => {
    for (let index = 0; index < data.length; index++) {
        if (
            typeof data[index].productId === undefined ||
            data[index].productId === ''
        ) {
            console.error(`SKU ${data[index]} requires a productId`);
            return false;
        }
    }
    return true;
};

const checkValueTypes = data => {
    for (let index = 0; index < data.length; index++) {
        for (let key in data[index]) {
            if (FirestoreNumberFields.includes(key) && key !== '') {
                if (isNaN(data[index][key])) {
                    console.error(
                        `SKU ${data[index].sku} has an invalid value type at ${key}`
                    );
                    return false;
                }
            }
        }
    }
    return true;
};

/**
 * Overview: handle is  required field in haku, check to see
 * if the bulk upload contains a handle field.
 * @param {Array} data
 */
const checkForHandles = data => {
    for (let item = 0; item < data.length; item++) {
        if (
            typeof data[item].handle === undefined ||
            data[item].handle === ''
        ) {
            console.error(`SKU ${data[item]} requires a handle`);
            return false;
        }
    }
    return true;
};

const injectProductSkus = data => {
    data.map(product => {
        if (product && product.sku && product.typeId !== 'variant') {
            product.productSkus = [product.sku];
        }
    });
};

/**
 * Overview: SKU is a required field. Check if it exists
 */
const checkForSKUs = data => {
    for (let item = 0; item < data.length; item++) {
        if (typeof data[item].sku === undefined || data[item].sku === '') {
            console.error(`SKU is a required field`);
            return false;
        }
    }
    return true;
};
/**
 * Overview: check to see if the skus inside the bulk upload
 * exist inside firestore
 * @param {Array} data
 */
const checkForDuplicateSKUs = data => {
    let isSKUValid;
    for (let item = 0; item < data.length; item++) {
        isSKUValid = validateSKUForBulkUpload(data[item].sku);
        if (isSKUValid === false) {
            console.error(`${data[item].SKU} is not valid`);
            return false;
        }
    }
    return true;
};

/**
 * Upload single item to Firestore
 * @param {String} collection - collection reference
 * @param {Object} item - Item to be added to Firestore
 */

const addDoc = async (collection, item) => {
    const collectionRef = firestore.collection(collection);
    await collectionRef
        .add(item)
        .then(() => {
            console.info(`${item.sku} successfully uploaded`);
        })
        .catch(error => {
            console.error(error);
        });
};

/**
 * Overview: Upload data to one of the collections in Firestore
 *
 * @param {Array} data - Firestore data
 * @param {String} collection  -  Collection data will be uploaded to.
 */
export const uploadToFirestore = (data, collection) => {
    try {
        const results = [];
        data.map(async item => {
            await results.push(addDoc(collection, item));
        });
        Promise.all(results);
    } catch (error) {
        console.error(error);
    }
};

/**
 * Overview: Once confirmed, upload data to the array of selected
 * Firestore collections.
 * @param {Array} data - Firestore data
 * @param {Array} collections  - Collections selected for data to be uploaded to.
 */

export const uploadVariationToFirestore = (data, collections) => {
    try {
        const results = [];
        for (let i = 0; i < collections.length; i += 1) {
            results.push(uploadToFirestore(data, collections[i]));
        }
        Promise.all(results);
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
};
