import React, { useState, useEffect } from 'react';
import theme from 'styles/theme';

import CssBaseline from '@material-ui/core/CssBaseline';
import { Admin, Resource } from 'react-admin';
import dataProvider from './dataProvider/DataProvider';
import AuthProvider from './auth/AuthProvider';
import {
    ProductList,
    ProductEdit,
    ProductCreate,
    CategoryList,
    CategoryEdit,
    CategoryCreate,
    IngredientCreate,
    IngredientEdit,
    IngredientList,
    BackupsList,
    BackupsEdit,
    LogsList,
    LogView
} from './resources';
import {
    initializeProductData,
    initializeCategoryData,
    initializeIngredientData
} from './validation';
import DashboardPage from './containers/DashboardPage';
import CustomLoginPage from './auth/CustomLoginPage';
import { catalogsConfig } from './config';

import firestore, { firestoreFirebase } from './config/firestore';
import firebaseAuthInstance from './config/firebaseAuth';
import { fetchUtils } from 'react-admin';
import { initializeStorage } from './dataProvider/util';

import { AUTH_LOGOUT } from 'react-admin';

const getCollectionList = collectionList =>
    !catalogsConfig.useTestCollections ? collectionList : '';

const App = () => {
    initializeProductData(firestore);
    initializeCategoryData(firestore);
    initializeIngredientData(firestore);

    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        const unregisterAuthObserver = firebaseAuthInstance
            .auth()
            .onAuthStateChanged(async user => {
                setIsSignedIn(!!user);

                const url = `https://us-central1-beautycounter-prod.cloudfunctions.net/bridgeAuth`;
                const auth = await firebaseAuthInstance.auth();
                try {
                    const token = await auth.currentUser.getIdToken();

                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`
                        }
                    });

                    const responseJson = await response.json();

                    firestoreFirebase
                        .auth()
                        .signInWithCustomToken(responseJson.access_token)
                        .then(userCredential => {
                            initializeStorage();
                        })
                        .catch(error => {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            console.error(errorCode, errorMessage);
                            AuthProvider(AUTH_LOGOUT);
                        });
                } catch (e) {
                    AuthProvider(AUTH_LOGOUT);
                }
            });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    return (
        <React.Fragment>
            <CssBaseline />
            <Admin
                title="Haku Catalog"
                theme={theme}
                authProvider={AuthProvider}
                dataProvider={dataProvider(process.env.REACT_APP_FIREBASE)}
                loginPage={CustomLoginPage}
                dashboard={DashboardPage}
            >
                <Resource
                    name="haku-backups"
                    list={getCollectionList(BackupsList)}
                    edit={BackupsEdit}
                    options={{ label: 'Backups' }}
                />
                <Resource
                    name={catalogsConfig.enUS.catalog}
                    list={ProductList}
                    edit={ProductEdit}
                    create={ProductCreate}
                    options={{ label: catalogsConfig.enUS.label }}
                />
                <Resource
                    name={catalogsConfig.enCA.catalog}
                    list={ProductList}
                    edit={ProductEdit}
                    create={ProductCreate}
                    options={{ label: catalogsConfig.enCA.label }}
                />
                <Resource
                    name={catalogsConfig.frCA.catalog}
                    list={ProductList}
                    edit={ProductEdit}
                    create={ProductCreate}
                    options={{ label: catalogsConfig.frCA.label }}
                />
                <Resource
                    name="categories-en-us"
                    list={getCollectionList(CategoryList)}
                    edit={CategoryEdit}
                    create={CategoryCreate}
                    options={{ label: 'Categories (En, US)' }}
                />
                <Resource
                    name="categories-en-ca"
                    list={getCollectionList(CategoryList)}
                    edit={CategoryEdit}
                    create={CategoryCreate}
                    options={{ label: 'Categories (En, CA)' }}
                />
                <Resource
                    name="categories-fr-ca"
                    list={getCollectionList(CategoryList)}
                    edit={CategoryEdit}
                    create={CategoryCreate}
                    options={{ label: 'Categories (Fr, CA)' }}
                />
                <Resource
                    name="filters-en-us"
                    list={getCollectionList(CategoryList)}
                    edit={CategoryEdit}
                    create={CategoryCreate}
                    options={{ label: 'Filters (En, US)' }}
                />
                <Resource
                    name="filters-en-ca"
                    list={getCollectionList(CategoryList)}
                    edit={CategoryEdit}
                    create={CategoryCreate}
                    options={{ label: 'Filters (En, CA)' }}
                />
                <Resource
                    name="filters-fr-ca"
                    list={getCollectionList(CategoryList)}
                    edit={CategoryEdit}
                    create={CategoryCreate}
                    options={{ label: 'Filters (Fr, CA)' }}
                />
                <Resource
                    name="ingredients-en-us"
                    list={getCollectionList(IngredientList)}
                    edit={IngredientEdit}
                    create={IngredientCreate}
                    options={{ label: 'Ingredients (En, US)' }}
                />
                <Resource
                    name="ingredients-en-ca"
                    list={getCollectionList(IngredientList)}
                    edit={IngredientEdit}
                    create={IngredientCreate}
                    options={{ label: 'Ingredients (En, CA)' }}
                />
                <Resource
                    name="ingredients-fr-ca"
                    list={getCollectionList(IngredientList)}
                    edit={IngredientEdit}
                    create={IngredientCreate}
                    options={{ label: 'Ingredients (Fr, CA)' }}
                />
                <Resource
                    name="haku-logs"
                    list={getCollectionList(LogsList)}
                    edit={LogView}
                    options={{ label: 'Product Logs' }}
                />
            </Admin>
        </React.Fragment>
    );
};

export default App;
