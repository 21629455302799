import { required } from 'react-admin';
import { noDuplicates } from './Product.js';

var firestore;
var categoryIds = [];

const getIds = collection => {
    var collectionRef = firestore.collection(collection);

    collectionRef.get().then(snapshot => {
        snapshot.forEach(doc => {
            const data = doc.data();

            if (!categoryIds.includes(data.categoryId)) {
                categoryIds.push(data.categoryId);
            }

            if (data.children) {
                for (var i = 0; i < data.children.length; i++) {
                    var childData = data.children[i];

                    if (!categoryIds.includes(childData.categoryId)) {
                        categoryIds.push(childData.categoryId);
                    }

                    if (childData.children) {
                        for (var j = 0; j < childData.children.length; j++) {
                            var gcData = childData.children[j];

                            if (!categoryIds.includes(gcData.categoryId)) {
                                categoryIds.push(gcData.categoryId);
                            }
                        }
                    }
                }
            }
        });
    });
};

export const initializeCategoryData = fs => {
    firestore = fs;

    getIds('categories-en-us');
    getIds('categories-en-ca');
    getIds('categories-fr-ca');

    getIds('filters-en-us');
    getIds('filters-en-ca');
    getIds('filters-fr-ca');
};

export const validateCategoryId = location => {
    var validations = [required('Category ID is required.')];

    if (location.pathname.includes('create')) {
        validations.push(
            noDuplicates(categoryIds, "Can't have a duplicate category ID.")
        );
    }

    return validations;
};
