export const processQuery = async (params, query, filters) => {
    if (filters.length) {
        filters.map((filter, index) => {
            query = query.where(filter, '==', params.filter[filter])
        })
    }

    if (query.id && query.id.includes('logs')) {
        query = query.orderBy('updateTime', 'desc')
    }

    const allDocs = await query.get().then(snapshot => snapshot.docs)
    const curDocs = [];

    if (allDocs.length > 0) {
        const startIndex = (params.pagination.page - 1) * params.pagination.perPage;
        const endIndex =
            startIndex + params.pagination.perPage > allDocs.length
                ? allDocs.length
                : startIndex + params.pagination.perPage;

        for (let i = startIndex; i < endIndex; i++) {
            const data = allDocs[i].data();
            curDocs.push({
                ...data,
                id: allDocs[i].id,
            });
        }
    }

    return {
        data: curDocs,
        total: allDocs.length,
    };
};
