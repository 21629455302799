import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    Filter,
    TextInput,
    BooleanField,
    downloadCSV,
    CloneButton
} from 'react-admin';

import { Parser } from 'json2csv';
import { ProductForm } from '../forms/ProductForm';
import { ProductEditForm } from '../forms/ProductEditForm';

const exporter = products => {
    const exportDate = new Date().toDateString();
    const fields = [
        'id',
        'sku',
        'productId',
        'draft',
        'status',
        'title',
        'description',
        'simpleDescription',
        'metaDescription',
        'howToUse',
        'ingredients.fullIngredients',
        'image.altText',
        'safetyFirstMessage',
        'barcode',
        'price',
        'inStock',
        'isShippable',
        'CV',
        'PV',
        'QV',
        'SV',
        'flags',
        'widgets',
        'hideFromEnrollingConsultant',
        'visibility'
    ];

    const options = { fields };

    const parser = new Parser(options);

    const productsForExport = [];

    products.map(product => {
        const { variants, visibility, flags } = product;

        productsForExport.push(product);

        if (variants && variants.length) {
            variants.map(variant =>
                productsForExport.push({
                    ...variant,
                    flags,
                    visibility
                })
            );
        }
    });

    const csv = parser.parse(productsForExport);

    downloadCSV(csv, `${exportDate}-Products`);
};

const ProductFilter = props => (
    <Filter {...props}>
        <TextInput label="Sku" source="sku" alwaysOn />
        <TextInput label="Title" source="title" alwaysOn />
    </Filter>
);

export const ProductList = props => (
    <List {...props} exporter={exporter} filters={<ProductFilter />}>
        <Datagrid>
            <TextField label="SKU" source="sku" sortable={true} />
            <TextField label="Product" source="title" sortable={true} />
            <BooleanField label="Draft" source="draft" sortable={true} />
            <EditButton />
            <CloneButton />
        </Datagrid>
    </List>
)

export const ProductCreate = props => (
    <Create {...props}>
        <ProductForm {...props} />
    </Create>
);

export const ProductEdit = props => {
    return (
        <Edit title="Edit" {...props}>
            <ProductEditForm {...props} />
        </Edit>
    );
};
