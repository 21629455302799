import React from 'react';
import {
    SimpleForm,
    TextField,
    Toolbar,
    SaveButton,
    ArrayField,
    Datagrid
} from 'react-admin';

import { diff } from 'deep-diff'

const sanitizeRestProps = ({
    anyTouched,
    array,
    asyncValidate,
    asyncValidating,
    autofill,
    blur,
    basePath,
    change,
    clearAsyncError,
    clearFields,
    clearSubmit,
    clearSubmitErrors,
    destroy,
    dirty,
    dispatch,
    form,
    handleSubmit,
    hasList,
    hasEdit,
    hasCreate,
    hasShow,
    initialize,
    initialized,
    initialValues,
    pristine,
    pure,
    redirect,
    reset,
    resetSection,
    submit,
    submitFailed,
    submitSucceeded,
    submitting,
    touch,
    translate,
    triggerSubmit,
    untouch,
    valid,
    validate,
    ...props
}) => props;

const LogViewToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
)

const filterFieldNames = (path) => {
    const killFields = ['fields', 'mapValue', 'arrayValue', 'integerValue', 'stringValue', 'booleanValue', 'values']

    return path.filter((value, index, arr) => !killFields.includes(value)).join(' » ');
} 

const prepareBeforeAndAfter = (beforeSave, afterSave, record) => {
    const diffs = diff(beforeSave, afterSave)
    const diffsJson = JSON.parse(JSON.stringify(diffs))

    record.edits = []
    record.adds = []
    record.deletes = []

    diffsJson
        .filter(type => type.kind === 'E')
        .reduce((prev, current) => {
            record.edits.push({
                fieldName: filterFieldNames(current.path),
                beforeSave: current.lhs,
                afterSave: current.rhs,
            });
        }, {})

    diffsJson
        .filter(type => type.kind === 'D')
        .reduce((prev, current) => {
            record.deletes.push({
                fieldName: filterFieldNames(current.path),
                value: current.lhs,
            });
        }, {})

    diffsJson
        .filter(type => type.kind === 'N')
        .reduce((prev, current) => {
            record.adds.push({
                fieldName: filterFieldNames(current.path),
                value: current.rhs,
            });
        }, {})
}

export const LogForm = (props) => {
    const { record } = props
    const { beforeSave, afterSave } = record.meta

    prepareBeforeAndAfter(beforeSave, afterSave, record)

    record.updateTime = record.updateTime ? record.updateTime = new Date(record.updateTime).toLocaleString() : 'N/A'

    return <SimpleForm {...sanitizeRestProps(props)} toolbar={<LogViewToolbar />}>
            <TextField label="Catalog path" source="catalogPath" />
            <TextField label="Sku before save" source="oldSku" />
            <TextField label="Sku after save" source="newSku" />
            <TextField label="Update time" source="updateTime" />
            <TextField label="Updated by" source="updatedByEmail" />
            <TextField label="Product created" source="meta.beforeSave.createTime" />
            <ArrayField label="Fields Edited" source="edits">
                <Datagrid>
                    <TextField source="fieldName" />
                    <TextField source="beforeSave" />
                    <TextField source="afterSave" />
                </Datagrid>
            </ArrayField>
            <ArrayField label="Fields Deleted" source="deletes">
                <Datagrid>
                    <TextField source="fieldName" />
                    <TextField source="value" />
                </Datagrid>
            </ArrayField>
            <ArrayField label="Fields Added" source="adds">
                <Datagrid>
                    <TextField source="fieldName" />
                    <TextField source="value" />
                </Datagrid>
            </ArrayField>
        </SimpleForm>;
}
