/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import RichTextInput from 'ra-input-rich-text';
import {
    TextInput,
    TextField,
    BooleanInput,
    LongTextInput,
    ArrayInput,
    SimpleFormIterator,
    TabbedForm,
    FormTab,
    SelectInput,
    SelectArrayInput,
    FormDataConsumer,
    ImageField,
    ImageInput,
    CheckboxGroupInput,
    Labeled,
    DateInput,
    required
} from 'react-admin';
import NumberInput from '../components/ReactAdminCustom/NumberInput';
import { Button } from '@material-ui/core';
import { FormToolbar } from '../components/FormToolbar';
import {
    validateType,
    validateProdId,
    validateSKU,
    validateStatus,
    validateVisibility,
    validateHandle,
    validateStringPricing,
    validateNumberPricing,
    volumeErrorMsg
} from '../validation/Product.js';
import { ProductGallery } from '../components/ProductImages.js';
import {
    CategoryPositionInformation,
    CategoryUpSellIdInformation,
    CategoriesInputInformation,
    ParentCategoriesInputInformation
} from '../components/ProductComponents.js';
import BundleEdit from '../components/BundleEdit';
import VariantEdit from '../components/VariantEdit';
import {
    UserTypes,
    LanguageVariants,
    Flags,
    Statuses,
    Sizes,
    Visibility,
    ProductTypes,
    FinalSaleTypes,
    VariantStyles
} from '../constants/returnTypes';
import { catalogsConfig } from '../config';

export class ProductForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newTo: null,
            newFrom: null
        }
    }
    sanitizeRestProps = ({
        anyTouched,
        array,
        asyncValidate,
        asyncValidating,
        autofill,
        blur,
        change,
        clearAsyncError,
        clearFields,
        clearSubmit,
        clearSubmitErrors,
        destroy,
        dirty,
        dispatch,
        form,
        handleSubmit,
        hasList,
        hasEdit,
        hasCreate,
        hasShow,
        initialize,
        initialized,
        initialValues,
        pristine,
        pure,
        redirect,
        reset,
        resetSection,
        submit,
        submitFailed,
        submitSucceeded,
        submitting,
        touch,
        translate,
        triggerSubmit,
        untouch,
        valid,
        validate,
        ...props
    }) => props;

    getDefaultLanguage = () => {
        switch (this.props.basePath) {
            case `/${catalogsConfig.enUS.catalog}`:
                return 'us';
            case `/${catalogsConfig.enCA.catalog}`:
                return 'en-ca';
            case `/${catalogsConfig.frCA.catalog}`:
                return 'fr-ca';
            default:
                throw new Error('Not a supported basePath');
        }
    };

    getLinkLabel = link => {
        switch (this.props.record.links[link].split('/')[1]) {
            case catalogsConfig.enUS.catalog:
                return 'US Edit';
            case catalogsConfig.enCA.catalog:
                return 'Canada En Edit';
            case catalogsConfig.frCA.catalog:
                return 'Canada Fr Edit';
            default:
                throw new Error('Not a supported resource');
        }
    };

    getLinkButtons = () => {
        var links = this.props.record.links;

        if (links.length > 1) {
            return (
                <Labeled label="Languange Variations">
                    <div style={{ display: 'grid' }}>
                        <Button
                            href={links[0]}
                            variant="outlined"
                            color="primary"
                            style={{ margin: '10px 10px 0px 5px' }}
                        >
                            {this.getLinkLabel(0)}
                        </Button>
                        <Button
                            href={links[1]}
                            variant="outlined"
                            color="primary"
                            style={{ margin: '10px 10px 0px 5px' }}
                        >
                            {this.getLinkLabel(1)}
                        </Button>
                    </div>
                </Labeled>
            );
        }
        if (links.length === 1) {
            return (
                <Labeled label="Language Variations">
                    <div style={{ display: 'grid' }}>
                        <Button
                            href={links[0]}
                            variant="outlined"
                            color="primary"
                            style={{ margin: '10px 10px 0px 5px' }}
                        >
                            {this.getLinkLabel(0)}
                        </Button>
                    </div>
                </Labeled>
            );
        }
    };

    render() {
        return (
            <TabbedForm
                {...this.sanitizeRestProps(this.props)}
                toolbar={<FormToolbar isCreate={true} />}
            >
                <FormTab label="Main">
                    <SelectInput
                        label="Type"
                        source="typeId"
                        validate={validateType}
                        choices={ProductTypes}
                    />
                    {this.props.location.pathname.includes('create') && (
                        <CheckboxGroupInput
                            label="Language Variations"
                            source="variations"
                            defaultValue={[this.getDefaultLanguage()]}
                            choices={LanguageVariants}
                        />
                    )}
                    <SelectArrayInput
                        label="Flags"
                        source="flags"
                        choices={Flags}
                    />
                    <TextInput label="Title" source="title" validate={required()} />
                    <NumberInput label="UPC" source="upc" />
                    <TextInput
                        label="SKU"
                        source="sku"
                        validate={validateSKU(this.props.location)}
                    />
                    {this.props.location.pathname.includes('create') &&
                        <NumberInput
                            label="Product ID"
                            source="productId"
                            validate={validateProdId()}
                        />
                    }
                    <BooleanInput
                        label="Draft"
                        source="draft"
                        defaultValue={true}
                    />
                    <SelectInput
                        label="Status"
                        source="status"
                        isRequired={true}
                        defaultValue={'1'}
                        validate={validateStatus}
                        choices={Statuses}
                    />
                    <SelectInput
                        label="Is Final Sale Config"
                        source="isFinalSaleConfig"
                        choices={FinalSaleTypes}
                    />
                    <BooleanInput
                        label="In Stock"
                        source="inStock"
                        defaultValue={true}
                    />
                    <BooleanInput
                        label="Do Not Ship Alone"
                        source="doNotShipAlone"
                        defaultValue={false}
                    />
                    <BooleanInput
                        label="Is GWP"
                        source="isGWP"
                        defaultValue={false}
                    />
                    <BooleanInput label="Best Seller" source="bestSeller" />
                    <BooleanInput label="Is Final Sale?" source="isFinalSale" />
                    <BooleanInput label="Is Virtual" source="isVirtual" />
                    <BooleanInput
                        label="Include in Product Feed"
                        source="includeInProductFeed"
                        defaultValue={true}
                    />
                    <BooleanInput
                        label="Hide From Enrolling Consultant"
                        source="hideFromEnrollingConsultant"
                    />
                    <SelectInput label="Size" source="size" choices={Sizes} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if (
                                formData &&
                                formData.size &&
                                formData.size === 'custom'
                            ) {
                                return (
                                    <TextInput label="Custom Size" source="size" />
                                );
                            }
                        }}
                    </FormDataConsumer>
                    <TextInput label="Weight" source="weight" />
                    <TextInput label="Product Volume" source="productVolume" />
                    <BooleanInput label="Pre-Order" source="preOrder" />
                    <BooleanInput
                        label="Is Shippable"
                        source="isShippable"
                        defaultValue={true}
                    />
                    <BooleanInput label="Stock Notify" source="stockNotify" />
                    <NumberInput
                        label="Max Quantity"
                        source="maxQuantity"
                        defaultValue={10}
                        isRequired={true}
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if (
                                formData &&
                                formData.typeId &&
                                formData.typeId === 'sample'
                            ) {
                                formData.visibility = '1';
                            }
                        }}
                    </FormDataConsumer>
                    <SelectInput
                        label="Visibility"
                        source="visibility"
                        validate={validateVisibility}
                        choices={Visibility}
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            const changedNewFrom =
                                formData.newFrom &&
                                formData.newFrom !== this.state.newFrom;
                            if (changedNewFrom) {
                                const dt = new Date(formData.newFrom);
                                if (!dt.toString().includes("(Pacific Daylight Time)")) { //
                                    dt.setDate(dt.getDate() + 1); //To counter the missing day due to the internal treating 'YYYY-MM-DD' as YYYY-MM-DDT00:00:00.000Z, and always a day behind when converting to PST/PDT
                                }
                                dt.setMonth(dt.getMonth() + 6);
                                this.setState({
                                    newTo: new Date(dt)
                                        .toISOString()
                                        .split('T')[0],
                                    newFrom: formData.newFrom
                                });
                            }
                            return (
                                <Fragment>
                                    <DateInput
                                        label="New From"
                                        source="newFrom"
                                    />

                                    <DateInput
                                        label="New To"
                                        source="newTo"
                                        defaultValue={this.state.newTo}
                                    />
                                </Fragment>
                            );
                        }}
                    </FormDataConsumer>
                    <ArrayInput label="Ingredient SKUs" source="bundleIds">
                        <SimpleFormIterator>
                            <NumberInput label="SKU" source="" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput label="Widgets" source="widgets">
                        <SimpleFormIterator>
                            <TextInput label="Key" source="" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput
                        label="Visibility Range for User Types"
                        source="userTypes"
                    >
                        <SimpleFormIterator>
                            <SelectInput
                                label="User Type"
                                source="userType"
                                choices={UserTypes}
                            />
                            <SelectInput
                                label="Visibility"
                                source="visibility"
                                choices={Visibility}
                            />
                            <DateInput
                                label="Visibility Start Date"
                                source="visibleDateRange.fromDate"
                            />
                            <DateInput
                                label="Visibility End Date"
                                source="visibleDateRange.toDate"
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput label="Certifications" source="certifications">
                        <SimpleFormIterator>
                            <TextInput label="Certification Name" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Pricing">
                    <TextInput
                        label="Price"
                        source="price"
                        validate={validateStringPricing(this.props.location, true)}
                    />
                    <TextField
                        label="Price in cents (read-only)"
                        source="priceInCents"
                    />
                    <TextInput label="Personal Volume (PV)" source="PV" validate={validateStringPricing(this.props.location, true, volumeErrorMsg)} />
                    <TextInput label="Qualifying Volume (QV)" source="QV" validate={validateStringPricing(this.props.location, true, volumeErrorMsg)} />
                    <TextInput label="Commissionable Volume (CV)" source="CV" validate={validateStringPricing(this.props.location, true, volumeErrorMsg)} />
                    <NumberInput label="Host Value" source="hostValue" />
                    <NumberInput
                        label="Wholesale Price"
                        source="wholesalePrice"
                        validate={validateNumberPricing(this.props.location, false)}
                    />
                    <TextInput
                        label="Tax Class"
                        source="taxClass"
                        defaultValue="2"
                    />
                    <TextInput
                        label="Special Price"
                        source="specialPrice"
                        validate={validateStringPricing(this.props.location, false)}
                    />
                    <DateInput
                        label="Special Price From"
                        source="specialPriceFrom"
                    />
                    <DateInput label="Special Price To" source="specialPriceTo" />
                    <ArrayInput label="Group Price" source="groupPrices">
                        <SimpleFormIterator>
                            <NumberInput label="Website ID" source="websiteId" />
                            <TextInput
                                label="Customer Group"
                                source="customerGroup"
                            />
                            <TextInput
                                label="Price"
                                source="price"
                                validate={validateStringPricing(
                                    this.props.location,
                                    false
                                )}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Text">
                    <TextInput label="Promo Name" source="promoName" />
                    <TextInput label="Alt Promotion" source="altPromotion" />
                    <TextInput
                        label="Handle"
                        source="handle"
                        validate={validateHandle(this.props.location)}
                    />
                    <LongTextInput label="How To Use" source="howToUse" />
                    <LongTextInput
                        label="Short Description"
                        source="shortDescription"
                    />
                    <RichTextInput
                        label="Description"
                        source="description"
                        isRequired={true}
                    />
                    <RichTextInput
                        label="Meta Description"
                        source="metaDescription"
                    />
                    <LongTextInput
                        label="Full Ingredients"
                        source="ingredients.fullIngredients"
                    />
                    <LongTextInput
                        label="Active Ingredients"
                        source="ingredients.activeIngredients"
                    />
                    <ArrayInput
                        label="Key Ingredients"
                        source="ingredients.keyIngredients"
                    >
                        <SimpleFormIterator>
                            <TextInput label="Ingredient" source="" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput
                        label="After Ingredients"
                        source="ingredients.afterIngredients"
                    >
                        <SimpleFormIterator>
                            <TextInput label="After Ingredient" source="" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <BooleanInput label="Not Crawlable" source="notCrawlable" />
                    <BooleanInput
                        label="Disable Product Detail Page"
                        source="disableProductDetailPage"
                    />
                    <TextInput label="Recycling Info" source="recyclingInfo" />
                    <TextInput
                        label="Custom Out Of Stock Text"
                        source="customOOS"
                    />
                    <LongTextInput label="Best For" source="bestFor" />
                    <LongTextInput
                        label="Product Variety"
                        source="productVariety"
                    />
                    <RichTextInput
                        label="Safety First Message"
                        source="safetyFirstMessage"
                    />
                    <TextInput label="Metadata Title" source="metadata.title" />
                    <TextInput
                        label="Metadata Keywords"
                        source="metadata.keywords"
                    />
                    <RichTextInput
                        label="Metadata Description"
                        source="metadata.description"
                    />
                </FormTab>
                <FormTab label="Images and Videos">
                    <ImageInput label="Image" source="image.imageUrl">
                        <ImageField source="" />
                    </ImageInput>
                    <TextInput label="Image Alt Text" source="image.altText" />
                    <ProductGallery {...this.props} />
                    <TextInput label="Video" source="video.videoLink" />
                    <TextInput label="Alt Video" source="fields.altVideo" />
                    <ImageInput
                        label="Bundled Sku Interrupt Image"
                        source="image.bundledSkuInterruptImageUrl"
                    >
                        <ImageField source="" />
                    </ImageInput>
                    <TextInput
                        label="Bundled Sku Interrupt Image"
                        source="image.bundledSkuInterruptImageAltText"
                    />
                </FormTab>
                <FormTab label="Categories">
                    <CategoriesInputInformation {...this.props} />
                    <ParentCategoriesInputInformation {...this.props} />
                    <CategoryPositionInformation {...this.props} />
                    <CategoryUpSellIdInformation {...this.props} />
                    <TextInput
                        label="Google Product Category"
                        source="googleProductCategory"
                    />
                </FormTab>
                <FormDataConsumer>
                    {({ formData, dispatch, ...rest }) => {
                        if (
                            formData &&
                            formData.typeId &&
                            formData.typeId === 'bundle'
                        ) {
                            return (
                                <FormTab label="Bundles" {...rest}>
                                    <BundleEdit {...this.props} />
                                </FormTab>
                            );
                        }
                        if (
                            formData &&
                            formData.typeId &&
                            formData.typeId === 'configurable'
                        ) {
                            return (
                                <FormTab label="Variants" {...rest}>
                                    <SelectInput
                                        label="Variant Style"
                                        source="variantConfig.type"
                                        choices={VariantStyles}
                                    />
                                    <TextInput
                                        label="Variant Select Text"
                                        source="variantConfig.selectText"
                                    />
                                    <ArrayInput label="Variants" source="variants">
                                        <SimpleFormIterator>
                                            <VariantEdit {...this.props} />
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </FormTab>
                            );
                        }
                    }}
                </FormDataConsumer>
            </TabbedForm>
        );
    };
};

