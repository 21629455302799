/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import RichTextInput from 'ra-input-rich-text';
import {
    TextInput,
    BooleanInput,
    LongTextInput,
    ArrayInput,
    SimpleFormIterator,
    FormDataConsumer,
    TabbedForm,
    FormTab,
    SelectInput,
    SelectArrayInput,
    Labeled,
    DateInput,
    DateTimeInput,
    TextField,
    required
} from 'react-admin';
import { Button, Divider } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import NumberInput from '../components/ReactAdminCustom/NumberInput';
import VariantEdit from '../components/VariantEdit';
import BundleEdit from '../components/BundleEdit';
import IngredientAction from '../components/IngredientAction/IngredientAction';
import { FormToolbar } from '../components/FormToolbar';
import {
    validateProdId,
    validateSKU,
    validateHandle,
    validateStringPricing,
    validateNumberPricing,
    volumeErrorMsg
} from '../validation/Product.js';
import { getUser } from '../auth/AuthConfig';

import AutoShipSettingsComponent from '../components/AutoshipSettingsComponent';

import {
    CategoriesInputInformation,
    ParentCategoriesInputInformation,
    CategoryPositionInformation,
    CategoryUpSellIdInformation
} from '../components/ProductComponents';
import { ProductGallery, ProductImage } from '../components/ProductImages';

import {
    UserTypes,
    Flags,
    Statuses,
    Sizes,
    Visibility,
    FinalSaleTypes,
    VariantStyles
} from '../constants/returnTypes';
import { catalogsConfig } from '../config';
import { parse } from '@babel/core';

const styles = theme => ({
    details: {
        width: '90%',
        display: 'inline-grid'
    }
});

export const ProductEditForm = props => {
    const getDefaultLanguage = () => {
        switch (props.basePath) {
            case `/${catalogsConfig.enUS.catalog}`:
                return 'us';
            case `/${catalogsConfig.enCA.catalog}`:
                return 'en-ca';
            case `/${catalogsConfig.frCA.catalog}`:
                return 'fr-ca';
            default:
                throw new Error('Not a supported basePath');
        }
    };

    const getLinkLabel = link => {
        switch (props.record.links[link].split('/')[1]) {
            case 'products-en-us':
                return 'US Edit';
            case 'products-en-ca':
                return 'Canada En Edit';
            case 'products-fr-ca':
                return 'Canada Fr Edit';
            default:
                throw new Error('Not a supported resource');
        }
    };

    const getLinkButtons = () => {
        const { links } = props.record;

        if (links.length > 1) {
            return (
                <Labeled label="Languange Variations">
                    <div style={{ display: 'grid' }}>
                        <Button
                            href={links[0]}
                            variant="outlined"
                            color="primary"
                            style={{ margin: '10px 10px 0px 5px' }}
                        >
                            {getLinkLabel(0)}
                        </Button>
                        <Button
                            href={links[1]}
                            variant="outlined"
                            color="primary"
                            style={{ margin: '10px 10px 0px 5px' }}
                        >
                            {getLinkLabel(1)}
                        </Button>
                    </div>
                </Labeled>
            );
        }
        if (links.length === 1) {
            return (
                <Labeled label="Language Variations">
                    <div style={{ display: 'grid' }}>
                        <Button
                            href={links[0]}
                            variant="outlined"
                            color="primary"
                            style={{ margin: '10px 10px 0px 5px' }}
                        >
                            {getLinkLabel(0)}
                        </Button>
                    </div>
                </Labeled>
            );
        }
        return null;
    };

    return (
        <TabbedForm {...props} toolbar={<FormToolbar isCreate={false} />}>
            <FormTab label="Main">
                {props.record.unMergedChanges && (
                    <Alert
                        style={{ width: '100%', marginTop: `15px` }}
                        severity="warning"
                    >
                        <AlertTitle>Warning</AlertTitle>
                        {`This SKU has changes that were not merged to production. Verify with ${props
                            .record.lastEditedBy || 'UNKNOWN USER'}`}
                    </Alert>
                )}
                {!props.location.pathname.includes('create') &&
                    props.record.links &&
                    getLinkButtons()}
                <SelectArrayInput
                    label="Flags"
                    source="flags"
                    choices={Flags}
                />
                <TextInput label="Title" source="title" validate={required()} />
                <NumberInput label="UPC" source="upc" />
                <TextInput
                    label="SKU"
                    source="sku"
                    validate={validateSKU(props.location)}
                />
                <NumberInput
                    label="Product ID"
                    validate={
                        props.location.pathname.includes('create') &&
                        validateProdId()
                    }
                    source="productId"
                    disabled={!props.location.pathname.includes('create')}
                />
                <BooleanInput
                    label="Draft"
                    source="draft"
                    defaultValue={true}
                />
                <SelectInput
                    label="Status"
                    source="status"
                    defaultValue={1}
                    choices={Statuses}
                />
                <SelectInput
                    label="Is Final Sale Config"
                    source="isFinalSaleConfig"
                    choices={FinalSaleTypes}
                />
                <BooleanInput
                    label="In Stock"
                    source="inStock"
                    defaultValue={true}
                />
                <BooleanInput
                    label="Do Not Ship Alone"
                    source="doNotShipAlone"
                    defaultValue={false}
                />
                <BooleanInput
                    label="Is GWP"
                    source="isGWP"
                    defaultValue={false}
                />
                <BooleanInput label="Best Seller" source="bestSeller" />
                <BooleanInput label="Is Virtual" source="isVirtual" />
                <BooleanInput
                    label="Include in Product Feed"
                    source="includeInProductFeed"
                    defaultValue={true}
                />
                <BooleanInput
                    label="Hide From Enrolling Consultant"
                    source="hideFromEnrollingConsultant"
                />
                <SelectInput label="Size" source="size" choices={Sizes} />
                {/* set custom  */}
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (
                            formData &&
                            formData.size &&
                            formData.size === 'custom'
                        ) {
                            return (
                                <TextInput
                                    label="Custom Size"
                                    source="custom"
                                />
                            );
                        }
                    }}
                </FormDataConsumer>
                <TextInput label="Weight" source="weight" />
                <TextInput label="Product Volume" source="productVolume" />
                <BooleanInput label="Pre-Order" source="preOrder" />
                <BooleanInput
                    labe="Pre Order Consultant"
                    source="preOrderConsultant"
                />
                <BooleanInput label="Stock Notify" source="stockNotify" />
                <NumberInput
                    label="Max Quantity"
                    source="maxQuantity"
                    defaultValue={10}
                    isRequired={true}
                />
                <SelectInput
                    label="Visibility"
                    source="visibility"
                    validate={required()}
                    choices={Visibility}
                />
                <DateInput label="New From" source="newFrom" />
                <DateInput label="New To" source="newTo" />

                <ArrayInput label="Ingredient SKUs" source="bundleIds">
                    <SimpleFormIterator>
                        <NumberInput
                            validate={required()}
                            label="SKU"
                            source=""
                        />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput label="Widgets" source="widgets">
                    <SimpleFormIterator>
                        <TextInput label="Key" source="" />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput
                    label="Visibility Range for User Types"
                    source="userTypes"
                >
                    <SimpleFormIterator>
                        <SelectInput
                            label="User Type"
                            source="userType"
                            choices={UserTypes}
                        />
                        <SelectInput
                            label="Visibility"
                            source="visibility"
                            choices={Visibility}
                        />
                        <DateInput
                            label="Visibility Start Date"
                            source="visibleDateRange.fromDate"
                        />
                        <DateInput
                            label="Visibility End Date"
                            source="visibleDateRange.toDate"
                        />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput label="Certifications" source="certifications">
                    <SimpleFormIterator>
                        <TextInput label="Certification Name" />
                    </SimpleFormIterator>
                </ArrayInput>
                <AutoShipSettingsComponent
                    autoshipEligible="autoshipEligible"
                    autoshipFrequency="autoshipFrequency"
                    autoshipPeriod="autoshipPeriod"
                />
            </FormTab>
            <FormTab label="Pricing">
                <TextInput
                    label="Price"
                    source="price"
                    validate={validateStringPricing(props.location, true)}
                />
                <TextField
                    label="Price in cents (read-only)"
                    source="priceInCents"
                />
                <TextInput
                    label="Personal Volume (PV)"
                    source="PV"
                    validate={validateStringPricing(
                        props.location,
                        true,
                        volumeErrorMsg
                    )}
                />
                <TextInput
                    label="Qualifying Volume (QV)"
                    source="QV"
                    validate={validateStringPricing(
                        props.location,
                        true,
                        volumeErrorMsg
                    )}
                />
                <TextInput
                    label="Commissionable Volume (CV)"
                    source="CV"
                    validate={validateStringPricing(
                        props.location,
                        true,
                        volumeErrorMsg
                    )}
                />
                <NumberInput label="Host Value" source="hostValue" />
                <NumberInput
                    label="Wholesale Price"
                    source="wholesalePrice"
                    validate={validateNumberPricing(props.location, false)}
                />
                <TextInput
                    label="Tax Class"
                    source="taxClass"
                    defaultValue="2"
                />
                <TextInput
                    label="Special Price"
                    source="specialPrice"
                    validate={validateStringPricing(props.location, false)}
                />
                <DateInput
                    label="Special Price From"
                    source="specialPriceFrom"
                />
                <DateInput label="Special Price To" source="specialPriceTo" />
                <ArrayInput label="Group Price" source="groupPrices">
                    <SimpleFormIterator>
                        <NumberInput label="Website ID" source="websiteId" />
                        <TextInput
                            label="Customer Group"
                            source="customerGroup"
                        />
                        <TextInput
                            label="Price"
                            source="price"
                            validate={validateStringPricing(
                                props.location,
                                false
                            )}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Text">
                <TextInput label="Promo Name" source="promoName" />
                <TextInput label="Alt Promotion" source="altPromotion" />
                <TextInput
                    label="Handle"
                    source="handle"
                    validate={validateHandle(props.location)}
                />
                <LongTextInput label="How To Use" source="howToUse" />
                <LongTextInput
                    label="Short Description"
                    source="shortDescription"
                />

                <RichTextInput
                    label="Description"
                    source="description"
                    isRequired={true}
                />
                <RichTextInput
                    label="Meta Description"
                    source="metaDescription"
                />
                <LongTextInput
                    label="Full Ingredients"
                    source="ingredients.fullIngredients"
                />
                <IngredientAction {...props} />
                <LongTextInput
                    label="Active Ingredients"
                    source="ingredients.activeIngredients"
                />
                <ArrayInput
                    label="Key Ingredients"
                    source="ingredients.keyIngredients"
                >
                    <SimpleFormIterator>
                        <TextInput label="Ingredient" source="" />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput
                    label="After Ingredients"
                    source="ingredients.afterIngredients"
                >
                    <SimpleFormIterator>
                        <TextInput label="After Ingredient" source="" />
                    </SimpleFormIterator>
                </ArrayInput>
                <BooleanInput label="Not Crawlable" source="notCrawlable" />
                <BooleanInput
                    label="Disable Product Detail Page"
                    source="disableProductDetailPage"
                />
                <TextInput label="Recycling Info" source="recyclingInfo" />
                <TextInput
                    label="Custom Out Of Stock Text"
                    source="customOOS"
                />
                <LongTextInput label="Best For" source="bestFor" />
                <LongTextInput
                    label="Product Variety"
                    source="productVariety"
                />
                <RichTextInput
                    label="Safety First Message"
                    source="safetyFirstMessage"
                />
                <TextInput label="Metadata Title" source="metadata.title" />
                <TextInput
                    label="Metadata Keywords"
                    source="metadata.keywords"
                />
                <LongTextInput
                    label="Metadata Description"
                    source="metadata.description"
                />
                <TextInput label="Enrollment Info" source="information.info" />
                <TextInput label="Enrollment Label" source="label" />
                <TextInput label="Enrollment Value" source="value" />
                <ArrayInput
                    label="Enrollment - What's Inside"
                    source="whatsInside"
                >
                    <SimpleFormIterator>
                        <TextInput label="Sku" source="" />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput
                    label="Enrollment Line Info"
                    source="information.infoList"
                >
                    <SimpleFormIterator>
                        <TextInput label="Line Info" source="lineInfo" />
                        <NumberInput label="Ordinal" source="ordinal" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Images and Videos">
                <ProductImage />
                <ProductGallery />
                <TextInput label="Video" source="video.videoLink" />
                <TextInput label="Alt Video" source="altVideo" />
            </FormTab>
            <FormTab label="Categories">
                <CategoriesInputInformation {...props} />
                <ParentCategoriesInputInformation {...props} />
                <CategoryPositionInformation {...props} />
                <CategoryUpSellIdInformation {...props} />
                <TextInput
                    label="Google Product Category"
                    source="googleProductCategory"
                />
            </FormTab>
            <FormTab label="Variants">
                <SelectInput
                    label="Variant Style"
                    source="variantConfig.type"
                    choices={VariantStyles}
                />
                <TextInput
                    label="Variant Select Text"
                    source="variantConfig.selectText"
                />
                <ArrayInput label="Variants" source="variants">
                    <SimpleFormIterator>
                        <VariantEdit {...props} />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Bundled Options">
                <BundleEdit {...props} />
            </FormTab>
        </TabbedForm>
    );
};
