import { required } from 'react-admin';
import { noDuplicates } from './Product.js';

var firestore;
var ingredientIds = [];

export const getFirestoreCollection = async collection => {
    const firestoreIngredients = [];
    const collectionReference = await firestore.collection(collection);
    const getCollection = await collectionReference.get().then(snapshot => {
        snapshot.forEach(doc => {
            const data = doc.data();
            firestoreIngredients.push(data.title);
        });
    });

    return firestoreIngredients;
};

const getIngredientIds = collection => {
    var collectionRef = firestore.collection(collection);

    collectionRef.get().then(snapshot => {
        snapshot.forEach(doc => {
            const data = doc.data();

            if (!ingredientIds.includes(data.ingredientId)) {
                ingredientIds.push(data.ingredientId);
            }
        });
    });
};

export const initializeIngredientData = fs => {
    firestore = fs;

    getIngredientIds('ingredients-en-us');
    getIngredientIds('ingredients-en-ca');
    getIngredientIds('ingredients-fr-ca');
};

export const validateIngredientId = location => {
    var validations = [required('Ingredient ID is required.')];

    if (location.pathname.includes('create')) {
        validations.push(
            noDuplicates(ingredientIds, "Can't have a duplicate ingredient ID.")
        );
    }

    return validations;
};
