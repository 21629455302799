/**
 * Data Provider for cloud firestore db to interface with react-admin
 * Uses partially the firestore REST API https://firebase.google.com/docs/firestore/reference/rest/
 */
import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY
} from 'react-admin';
import {
    getProductList,
    getProduct,
    createProduct,
    updateProduct,
    deleteProduct
} from './Product';
import {
    getCategoryList,
    getCategory,
    createCategory,
    updateCategory,
    deleteCategory
} from './Category';
import {
    getIngredientList,
    getIngredient,
    createIngredient,
    updateIngredient,
    deleteIngredient
} from './Ingredient';
import { getBackupsList, getBackup, updateBackup } from './Backup';
import { getLogsList, getLog } from './Log';

import firestore from '../config/firestore';

const isProduct = resource => resource.includes('products');
const isCategory = resource => resource.includes('categories');
const isFilter = resource => resource.includes('filters');
const isIngredient = resource => resource.includes('ingredients');
const isBackup = resource => resource.includes('haku-backups');
const isLogs = resource => resource.includes('haku-logs');

export default database => {
    const db = database;

    const getList = (resource, params) => {
        if (isProduct(resource)) {
            return getProductList(firestore, resource, params);
        }
        if (isCategory(resource) || isFilter(resource)) {
            return getCategoryList(firestore, resource, params);
        }
        if (isIngredient(resource)) {
            return getIngredientList(firestore, resource, params);
        }
        if (isBackup(resource)) {
            return getBackupsList(firestore, resource, params);
        }
        if (isLogs(resource)) {
            return getLogsList(firestore, resource, params);
        }
    };

    const getOne = (resource, params) => {
        if (isProduct(resource)) {
            return getProduct(firestore, resource, params);
        }
        if (isCategory(resource) || isFilter(resource)) {
            return getCategory(firestore, resource, params);
        }
        if (isIngredient(resource)) {
            return getIngredient(firestore, resource, params);
        }
        if (isBackup(resource)) {
            return getBackup(firestore, resource, params);
        }
        if (isLogs(resource)) {
            return getLog(firestore, resource, params);
        }
    };

    const createDocument = (resource, params) => {
        if (isProduct(resource)) {
            return createProduct(firestore, resource, params);
        }
        if (isCategory(resource) || isFilter(resource)) {
            return createCategory(firestore, resource, params);
        }
        if (isIngredient(resource)) {
            return createIngredient(firestore, resource, params);
        }
        if (isCategory(resource) || isFilter(resource)) {
            return createCategory(firestore, resource, params);
        }
    };

    const updateDocument = (resource, params) => {
        if (isProduct(resource)) {
            return updateProduct(firestore, resource, params);
        }
        if (isCategory(resource) || isFilter(resource)) {
            return updateCategory(firestore, resource, params);
        }
        if (isIngredient(resource)) {
            return updateIngredient(firestore, resource, params);
        }
        if (isBackup(resource)) {
            return updateBackup(firestore, resource, params);
        }
    };

    const deleteDocument = (resource, params) => {
        if (isProduct(resource)) {
            return deleteProduct(db, resource, params);
        }
        if (isCategory(resource) || isFilter(resource)) {
            return deleteCategory(db, resource, params);
        }
        if (isIngredient(resource)) {
            return deleteIngredient(db, resource, params);
        }
    };

    return (type, resource, params) => {
        switch (type) {
            case GET_LIST:
                return getList(resource, params);
            case GET_ONE:
                return getOne(resource, params);
            case CREATE:
                return createDocument(resource, params);
            case UPDATE:
                return updateDocument(resource, params);
            case DELETE:
                return deleteDocument(resource, params);
            case UPDATE_MANY:
            case DELETE_MANY:
            case GET_MANY:
            case GET_MANY_REFERENCE:
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
    };
};
